
.admin-body {
    color :white;
    height : 100%;
    min-height: 100%;
    min-height: 100;    
    background-image: url("../images/admin_back.png");
    background-color : #252629;
    background-size:100% 100%;
    background-repeat : no-repeat;
}

.admin-body #root {
    height : auto;
}

.admin-body .container {
    display : inline-table;
    margin-left : 20px;
    height : auto;
}

.admin-body h2{
    font-weight: bold;
}
.admin-content{
    color : #fff;
}

.admin-content .muzped_button {
    color : #fff;
    border : 3px solid black;
}

#muzped-admin-table{
    text-transform: uppercase;

}

#muzped-admin-table th  {
    border-top : none;
    border-bottom : 3px solid white;
    font-weight : normal;
    font-size : 13px;
}

#muzped-admin-table .search-tr td  {
    border-top : none;
    border-bottom : 3px solid white ;
}

#muzped-admin-table  td , th {
    padding-top : 25px;
    padding-bottom:25px;
}

.left_border {
    border-right : 3px solid #fff;
    padding-right : 25px;
    padding-left : 25px;
    color : #fff;
    vertical-align: middle;


}

/*switch button*/
.switch-button {
    display : inline;
}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 25px;
    vertical-align: middle;
}

.switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 12px;
    left: 0;
    right: 19px;
    bottom: 0;

    -webkit-transition: .4s;
    transition: .4s;
    border-top: 2px solid white;
}

.slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 0px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    right : 15px;
}

.slider.round:before {
    border-radius: 50%;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
/* Rounded sliders */



/*switch button*/


#search_label{
    background-image :url("../images/search.png") ;

}

#filter_label{
    background-image :url("../images/filter.png") ;

}

.blue-back-link{
    color: #fff;
    background: #26547e;
    padding: 10px;
    cursor : default;
    vertical-align: middle;
    display: inline-block;

}



.item-form-input{
    background: transparent;
    border: none;
    font-size: 20px;
    margin-left: 15px;
    color : white;
    width : 720px;
    outline : none;
    
    
}

.item-form-input::placeholder:before{

    color : white;
    opacity : 1;
}

.not-required{
    opacity : 0.5;
}

.small-text{
    font-size : 12px;
}

.muzped-admin-button{
    border: 4px solid white;
    text-transform: uppercase;
    background: transparent;
    font-size: 30px;
    font-weight: bold;
    padding: 15px;
    color : white;
}

.table .form-section-title {
    font-size :12px;
   
    font-weight: normal;
    padding-bottom:35px;
}

.table .form-section-title b{
   
    padding-left : 100px;
    
}

.form-input-title{
    width :230px;
    display:inline-block;
    vertical-align: middle;
}

.admin-form-modal {
    height: 100%;
    overflow : auto;
    
    background : #252629;

}

.admin-form-modal #muzped-admin-table{
    background-color : #252629;
}

.quiz-admin-table td{
    padding-top : 10px;
    padding-bottom : 10px;
}

.quiz-admin-table .form-input-title{
    width : auto;
}

.quiz-admin-table .item-form-input{
    width : 300px;
    font-size : 15px;
    font-color : black;
}

.question-icon{
    background-image : url("../images/quiz_icon.png");
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: top;
}

.info-label{
    background-image : url("../images/info.png");
    width: 35px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-size: contain;
}


.admin-modal.modal{
    z-index : 150;
}

.admin-modal .modal-main{
    padding : 0px;
    position:fixed;
    min-height: 120vh;
    /*   margin-top :150px;*/

}

.admin-modal-header {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    height: 130px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    padding-left : 150px;
    padding-right : 150px;
    padding-top : 50px;
    position:fixed;
    background: #343a40;
    top : 0;
    width : 100%;
    z-index:2;
}

.admin-modal-content{
    padding-left : 150px;
    padding-right : 150px;
    margin-top : 350px;
    padding-bottom:200px;
}

.btn-question-delete{
    background-image : url("../images/question_delete.png");
    width: 20px;
    height: 20px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
}

.quiz-admin-table .form-section-title{
    padding-top : 35px;
}

.btn-add-question{
    background-image : url("../images/black_add.png");
    width: 35px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;
    margin-top: 300px;
    position : fixed;
    margin-top : 300px;
    margin-left : 100px;

}

.quiz-modal.modal{
    position:absolute;
    min-height: 100%;
    height : auto;
}

.modal-admin-body{
    overflow: hidden;
    scrollbar-width: none;
}

.squaredThree {
    width: 20px;
    position: relative;
    margin-right : 80px;
    float :right;
}
.squaredThree label {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;;
    border : 2px solid #252629;
  /*  box-shadow: inset 0px 1px 1px rgba(0,0,0,0.5), 0px 1px 0px rgba(255,255,255,.4);*/
}
.squaredThree label::after {
    content: '';
    width: 9px;
    height: 5px;
    position: absolute;
    top: 5px;
    left: 4px;
    border: 3px solid #252629;
    border-top: none;
    border-right: none;
    background: transparent;
    opacity: 0;
    transform: rotate(-45deg);
    z-index:0;
}
.squaredThree label:hover::after {
    opacity: 0.5;
}

.squaredThree  input[type=checkbox] {
    visibility: hidden;
}
.squaredThree .checked::after   {
    opacity: 1;
}    


/* end .squaredThree */
.select-module{
    cursor: default;
    font-weight : bold;
     background: #5db481;
    height: 40px;
    vertical-align: middle;
    line-height: 40px;
    padding: 10px;
    border: none;
    
}
.disable-module{
    opacity : 0.3;
    user-select : none;
    pointer-events : none;
   border : none ;
}

.admin-body .admin-title{
    font-weight: unset;
}


.black-link{
    color: #fff;

    padding: 10px;
    cursor : default;
    text-transform: uppercase;
    margin-left : 30px;    
}

.close-label{
    background-image : url("../images/close.png");
    width: 30px;
    height: 30px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left : 40px;
    vertical-align: middle;
}

.btn-add-image{

    width: 150px;
    height: 150px;
    display: inline-flex;
    vertical-align: middle;
    background-size:30px 30px;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;


    text-align:center;
    padding-top : 40px;
    text-transform: uppercase;
    font-weight : bold;
    color : white;
    cursor : grab;
}

.btn-add-image:hover{
    color : white;
}

.btn-add-item{
    background-image : url("../images/black_add.png");
    width: 150px;
    height: 150px;
    display: inline-block;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
    
    color : black;
    text-align:center;
    padding-top : 100px;
    text-transform: uppercase;
    font-weight : bold;
    position : fixed;
    margin-top : 200px;
    margin-left : 70px;
}

.add-memory-item .input-div{
    width: 100%;
    background: rgb(123,123,123,0.7);

    color: white;
    border: none;
    height: 60px;
    padding : 10px;

}

.add-memory-item input{
    width: 100%;
    background: none;
    border: none;
    color: black;
    opacity: 1;
    height: 100%;

}

.add-memory-item-content{
    width: 150px;
    height: 150px;
    text-align: center;
    background: #252629;
    background-size : cover;
    background-repeat : no-repeat;
    display : inline-block;
   
}

.add-memory-item{
    width:150px;
    display : inline-block;
    text-align : center;
  
    font-weight: bold;
    z-index:2;
    position:relative;
    
}

#picture-item-container .add-memory-item{
    width : 300px;
    margin-right : 20px;
    
}

.item-content{
    width: 200px;
    height: 200px;
    text-align: center;
     background : #252629;
    background-size : cover;
    background-repeat : no-repeat;
    display : inline-block;
    margin-right : 20px;
    margin-bottom : 10px;
    margin-top : 10px;
}

.item-input{
    width: 100%;
    background: none;
    border: none;
    color: white;
    opacity: 1;
    height: 100%;
    margin-bottom : 25px;
}

.item-main-delete{
    width : 200px;
    display: inherit;
    text-align: center;
    margin-left : unset;
    margin-top : 20px;
}

.item-delete{
    width : 200px;
    display : inline-block;
    margin-left : unset;
    text-align: center;
    margin-right : 20px;
}

.moving{
    position : absolute;
}
#module-list .moving{
    background : none;
}

#pairing-item-container .add-item {
    display : inline-block;
    width:320px;
}

#pairing-item-container .item-content{
    width : 200px;
    height : 200px;
     
}

#pairing-item-container .pair-content{
    border-radius: 0 0 120px 120px;
}

.paring-item-pair-space{
    margin-top : 5px;
    margin-bottom : 5px;
    border-bottom : 2px solid #252629;
    border-top : 2px solid #252629;
    height : 30px;
    width : 200px;
}

.paring-item-pair-space-middle{
    height: 15px;
    border-left : 2px solid #252629;
    margin : 0 auto;
    width : 2px;
    margin-top : 5px;
}

#pairing-item-container .item-delete{
    font-size : 12px;
    width : 200px;
    font-weight : bold;
}

#pairing-item-container .item-main-delete{
    width : 200px;
    font-size : 12px;
    font-weight : bold;
}

#pairing-item-container .item-content input{
    width: 100%;
    background: #C4C4C4;
    border: none;
    color: black;
    opacity: 0.7;
    height: 30px;
    padding-left : 10px;

}

#pairing-item-container .btn-add-image{
    padding-top : 20px;
}

.disabled-add-item{
    opacity : 0.3;
    pointer-events : none;
}

.pairing-text-input {
    padding-left : unset;
    text-align : center;
    
}


/*#muzped-admin-table td .form-border{
      
  
  border-bottom : 3px solid white;
        animation : borderAnim 1s normal;
        
}*/

#muzped-admin-table td{
    vertical-align: middle;
   /* padding-top : 10px;*/
}

.table td{
    padding: unset;
}


@keyframes borderAnim {
    from { transform: rotate(190deg)}
   
    to   {transform : rotate(360deg);
               
    }
    
}

@keyframes swim {
    from { top: -200px}
   
    to   {top : 0;
               
    }
    
}
.header_popup{
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    height: 130px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
 
    position:fixed;
    background: #252629;
    top : 0;
    width : 100%;
    z-index:2;
    left : 350px;
    animation : swim 0.3s normal;
}

.header_popup_content{
    position: absolute;
    padding-left: 50px;
    padding-top: 50px;
    width : 1200px;
}

.header_popup_input{
    border : none;
    background : transparent;
    padding-left : 30px;
    color :white;
    outline : none;
}

.hedaer_popup_input:focus{
    outline : none;
    border : none;
}
.header_popup .close-label{
    width : 30px;
    height : 30px;
    margin-left : 600px;
    display:inline-block;
}

#white_search_label{
    background-image :url("../images/search_white.png") ;
     margin-right : 20px;
     background-color : transparent;
}

.filters{
    float : left;
    text-align: left;
    text-transform: uppercase;
    left :0;
    border-top : 3px solid white;
    width : 100%;
    padding : 10px;
    padding-left : 200px;
    margin-top :10px;
    
    
}

.filters span{
    margin-right : 25px;
    cursor : default;
}

#white_filter_label{
    background-image :url("../images/filter_white.png") ;
     
     background-color : transparent;
     margin-left : 700px;
     height :30px;
     width : 30px;
     display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
   
    vertical-align: middle;
    float : right;
}

#image-container .image-div label{
    position : absolute;
    background-color : white;
    margin-left : -15px;
    
    width: 15px;
    height: 15px;
    
    background-repeat: no-repeat;
    background-size: 10px 10px;
    background-position: center;
    vertical-align: middle;
}

#image-container .image-div .image-delete{
     background-image: url("../images/image_delete.png") ;;
}

#image-container .image-div .image_change{
     background-image: url("../images/image_change.png") ;
     margin-top:15px;
}


#image-container .image-div{
   /* width:120px;
   
    height: 120px;
     margin-right : 35px;
    margin-bottom : 50px;*/
}

#image-container img{
    width : 120px;
    height : 120px;
   
    object-fit : cover;
}


.table-title{
    background: white;
    color: #343a40;
    height: 60px;
}

#popup {
  
  position: fixed;
  z-index: 230;
  

    width: 500px;
    filter :blur(0px);
   
    top : 50%;
    left : 50%;
    margin-left : -200px;
    margin-top : -100px;
    padding : 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.popup_error{
    background-color: #bf4d4d;
    
}

.popup_message {
    background-color:#27547e;
   
}

#popup.popup_delete  {
    background-color:#bf4d4d;
   padding : unset;
   padding-top : 20px;
}

#popup.popup_confirm {
    background-color:#27547e;
   padding : unset;
   padding-top : 20px;
}

.popup_message, .popup_confirm .message{
    font-size : 13px;
    text-align:center;
    margin-top : 20px;
   padding-bottom: 20px;
}

#popup .close-label{
    width  :20px;
    height:  20px;
    margin  : unset;
    float: right;
}

.popup_content {
 font-size : 17px;

  
}

.popup_body {
    filter: blur(2px);
}

.fa-popup-error{
    background-image: url("../images/error.png") ;;
    width: 20px;
    height: 20px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    
    vertical-align: middle;
}

.selected-table-title{
    background: #5db481;
}

.error-table-title{
    background: #bf4d4d;
}

#muzped-admin-table.form-table td{
    border-top : none;
    border-bottom : none;
}

.form-table .image-div{
    background : black;
    width : 100%;
    height : 300px;
    display:inline-block;
    margin : 0 auto;
    text-align : center;
   padding-top : 15px;
   margin-top : -150px;
   
}

.form-table img{
  
    width : 150px;
    height : 200px;
    margin : 0 auto;
    object-fit : contain;
}

.image-label{
    text-transform: none;
    font-size:12px;
}

#muzped-admin-table a{
    color : white;
    cursor : grab;
    padding-left : 25px;
    font-size : 14px;
}

#muzped-admin-table .list-table-title{
    background  : white;
    color : black;
    padding-left : 15px;
    border-bottom : 3px solid #343a40 !important;
   font-weight: bold;
}

#muzped-admin-table .list-table-title span{
   
   font-weight: normal;
}
 
#muzped-admin-table .list-table-right{
    padding-left : 20px;
}

#popup .popup_buttons button{
    width:50%;
    border : none;
    background : black;
    height : 60px;
    vertical-align: bottom ;
    color : white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-weight: bold;
}

.popup_delete .close-label{
    display:none;
}

.popup_confirm .close-label{
    display:none;
}

#popup .popup_buttons .delete-button{
    background-color : #bf4d4d;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.delete-link{
    background :#bf4d4d;
}

a:hover{
    text-decoration: none;
    color : white;
    cursor: grab;
}

#table-title-image label{
    cursor :grab;
    padding-left : 15px;
}

.image-div .small-text{
    font-weight : bold;
    cursor : grab;
}

#popup .popup_buttons .confirm-button{
    background-color : #5db481;
    color : black;
    
    
}

#muzped-admin-table .table-link{
   
    padding : 10px;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    margin-left: 5px;
}
#muzped-admin-table .blue-link:hover{
    background : #26547e;
   
}

#muzped-admin-table .delete-table-link:hover{
    background : #bf4d4d;
   
}

#muzped-admin-table .active-table-row td {
   
}

#muzped-admin-table .active-table-row a {
 
  opacity: 0.4;
}

#muzped-admin-table .active-table-row .list-table-title {
 
  background : #bf4d4d;
}

#muzped-admin-table .active-table-row span {
   opacity: 0.4;
}

#muzped-admin-table .active-table-row label {
    opacity: 0.4;
}

#muzped-admin-table .active-table-row .round:before {
    opacity: 0.9;
}

#muzped-admin-table .active-table-row .slider {
   opacity: 0.4;
}

.form-table .active_age{
    font-weight: bold;
}

#muzped-admin-table.form-table .table-detail{
    border-bottom : 3px solid white;
}

.file-popup{
    position: fixed;
    top: 50%;
    left: 50%;
    background: white;
    color: black;
    height: 600px;
    width: 900px;
    margin-left: -500px;
    margin-top: -300px;
    padding : 30px;
    z-index:230;
    
}

#muzped-admin-table .file-popup a{
    color : black;
}

.file-popup img{
    width: 150px;
    height: 150px;
    object-fit: cover;
    padding: 15px;
}

.image-div{
   
    text-align: center;
  
    word-break: break-word;
    margin-right : 10px;
    
    width: 150px;
   
  
    margin: 0 auto;
    display: inline-block;
    vertical-align: top;
    min-height: 200px;
  
    padding: 10px;
}

#image-container .image-div span{
        font-size: 10px;
    text-align: center;
    display: inherit;
    font-weight: bold;
    height: 70px;
    text-align: center;
    margin: 0 auto;
}

.file-popup .row{
    margin-left : unset;
    margin-top : 25px;
}

#filelist{
    height: 350px;
    overflow : auto;
    width : 100%;
}

.admin-body .table-dark{
    background : transparent;
}

#muzped-admin-table.media-table td{
    border-bottom : none;
    cursor : grab;
    border-top : none;
    padding-top : unset;
}

.media-table td span{
    border-bottom : 2px solid white;
    padding-bottom : 10px;
    width : 100%;
    display:inline-block;
    
}

.media-table  .active {
    font-weight: bold;
}

.media-table td li{
    list-style : none;
    text-transform: uppercase;
    font-size : 13px;
   
    padding-left : 20px;
    padding-top : 15px;
}


.memory-item-admin-back{
    background-image: url("../images/memoria_admin_back.png") ;
    width: 150px;
    height: 150px;
    background-size: 150px 150px;
    position: relative;
    top: -80px;
    left: -50px;
    z-index: 1;
}

.memory-item-admin{
    width : 200px;
    margin-left : 50px;
    display: inline-block;
   
}

.memory-item-admin .black-link{
   /* margin-left: 30px;
    border-bottom: 2px solid white;*/
    width: 100%;
    display: inline-block;
    font-size: 11px;
    font-weight: bold;
  /*  padding-top : 150px;*/
  margin-top : 160px;
  text-align: center;
}

.memory-item-admin .input-div input{
    width: 150px;
    background: transparent;
    border: none;
    color: white;
}

.add-memory-item-content .btn-add-image{
    padding-top : unset;
}

.page-header h2{
    margin-left : 45px;
    display : inline-block;
}

.page-header{
    
    box-shadow: 0 15px 15px -20px rgb(0 0 0 / 40%);
    height: 120px;
    padding-top: 40px;
   /* margin-left : -50px;*/
    position: fixed;
    /* width: 1250px; */
    top: 0;
    /* left: 400px; */
    background: #252629;
    width: inherit;
    z-index:100;
}

#info-label-header{
    background-image: url("../images/info.png") ;
    min-width: 40px;
    min-height: 40px;
    background-size: 40px 40px;
    background-position: center;
    display: inline-block;
    vertical-align: top;
    margin-left : 25px;
}

.admin-body #muzped-admin-table {
    margin-top : 120px;
}

#image-module-container{
  
        margin : 0 auto;
    width : 900px;
    height:500px;
}

#modal-header #search-input{
       float: right;
    vertical-align: bottom;
    height: 25px;
    display: inline; 
}

#modal-header #search-label{
    vertical-align: top;
   
    display: inline;

    
    
}

#modal-header #info-label-header{
    width:30px;
    height: 30px;
    vertical-align: middle;
    margin-right : 55px;
}

#muzped-admin-table.modal-table .list-table-title{
    text-transform: uppercase;
    border-bottom : 3px solid #252629 !important;
    font-weight:bold;
}
#muzped-admin-table.modal-table .list-table-title label{
    text-transform: unset;
    font-weight:normal;
}

#muzped-admin-table.modal-table tr{
    text-transform: uppercase;
    border-bottom : 3px solid white !important;
    font-weight:bold;
}

#pagination {
    
    text-align: center;
    margin-top : 140px;
}

#pagination span {
    margin: 10px;
    font-size: 18px;
    padding: 10px;
    border: 2px solid;
    cursor: grab;
}

#modal-container{
    width: 100%;
    max-height: 700px;
    position: absolute;
    z-index: 200;
    
    
}

#modal-container .modal-dialog{
    max-width: 1100px;
    background: #c4c4c4;
}

#modal-container .modal-header{
    color : black;
    border : none;
    padding : 35px;
}
#modal-container .modal-content{
    max-height:700px;
    min-height:700px;
    background: #c4c4c4;
    padding-bottom : 40px;
    margin-top : 80px;
}

#modal-container .modal-body{
    overflow-y: auto;
    z-index:200;
}

#modal-container .modal-header h2{
    font-size:18px !important;
    vertical-align : middle !important;
}

#modal-container .modal-header .info-label{
    height : 35px;
}

#modal-container .modal-header a{
    font-size:14px;
    font-weight : bold;
    padding: 7px !important;
    height: 35px;
    display: inline-block;
}


#modal-container .modal-header .close-label{
    background-image: url("../images/black_close.png")
}

.quiz-question-tr{
    background : #26547e;
}

.quiz-admin-table .quiz-question-tr td{
   border-bottom : unset;
   border-top : unset
}

.quiz-admin-table td{
    padding : 10px;
    border-bottom : 1px solid #252629;
    border-top : unset;
}

.quiz-question-tr .item-form-input::placeholder{
   color : white !important;
   opacity : 1;
   font-weight : bold !important;
}

.quiz-question-tr .item-form-input{
  color : white !important;
   font-weight : bold !important;
}

.quiz-admin-table .left_border{
    color : #252629;
    font-weight: bold;
    border-right : 2px solid #252629;
}

.quiz-admin-table .quiz-question-tr .left_border{
    color : white;
     border-right : 2px solid white;
    
}

.quiz-admin-table .item-form-input::placeholder{
    color : #252629;
    opacity : 1;
}

.quiz-admin-table .item-form-input{
    color : #252629;
    font-weight : normal;
}

.quiz-admin-table .form-section-title{
    border-bottom : unset;
}

.add-memory-item .delete-link{
    margin-left : 0;
    width : 200px;
    display:inline-block;
    margin-right : 20px;
}

.picquiz-question-tr td{
    border-bottom : unset;
}

.picquiz-question-tr .btn-question-delete{
    background : url("../images/black_close.png");
        background-size: 20px 20px;
    background-repeat: no-repeat;
}

.media-table-functions span , label{
    font-size:12px;
    cursor : grab;
}

#add-directory{
    position: absolute;
    top: 150px;
    left: 150px;
    z-index: 300;
    width: 480px;
    height: 80px;
    text-align: left;
    box-shadow: 10px 15px 15px 10px rgb(0 0 0 / 20%);
    background: #252629;
    padding : 20px;
    vertical-align: middle;
    padding-top : 30px;
}

#add-directory input{
    border: none;
    background: transparent;
    border-bottom: 2px solid white;
    outline: none;
    color: white;
    margin-left : 15px;
    margin-right : 15px;
    width : 310px;
}

.directory-list{
    list-style : none;
}

.directory-list span{
   border-bottom : 2px solid black;
   width : 100%;
   display:inline-block;
}

.directory-list li{
    padding-left : 25px;
    width : 100%;
    float:right;
    
     
}

.active-page{
    background: white;
    color: black;
    border: 2px solid white !important;
}

.edit-directory{
    border-bottom : unset !important;
    background: url("../images/ceruza.png");
    background-repeat: no-repeat;
    background-size:contain;
    display : inline-block;
    float:right;
    max-width: 20px;
}

.dragdirectory {
    font-weight : bold;
}

.no-edit-table .edit-directory{
    display : none;
}

.threed-form label{
    color :black;
    width : 150px;
}

.threed-form input {
    border-bottom : 2px solid black;
    color : black;
    outline: none;
}

.threed-addfile{
    min-width : 300px;
    font-size : 16px;
}

.statistic_table{
    max-width:80%;
    margin:0 auto;
}

.directory-table-div {
    scrollbar-color: #6969dd #e0e0e0;
    scrollbar-width: thin;
    overflow: auto;
    max-height: 600px;
    margin-top: 120px;
    max-width: 400px;
    position : fixed;

}

.directory-table-div #muzped-admin-table{
    margin-top : unset !important;
   
}


.directory-table-div::-webkit-scrollbar {
    width: 10px;
}

.directory-table-div::-webkit-scrollbar-track {
    background-color: darkgrey;
}

.directory-table-div::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}


#image-container{
     margin-left : 410px;
}

#ages .active_age{
    background: #5db481;
    height: 40px;
    vertical-align: middle;
    line-height: 40px;
}

#ages .age-div{
    display: inline-block;
    width: 90px;
    text-align: center;
    margin-right: 20px;
}

#eye-i{
    background: url('../images/forview.png');
    min-width: 30px;
    min-height: 20px;
    background-size: 30px 20px;
    background-position: center;
    display: inline-block;
    vertical-align: top;
    margin-left : 25px;
}

.form-input-title-eye{
    text-align: right;
    vertical-align: top !important;
    padding-right: 15px;
    padding-top: 5px;
}

.statistic-table {
    padding : 35px;
    overflow : auto;
    max-height: 300px;
}

.statistic-table tr{
    
    height : 60px;
    background : #252629;
    border-bottom :  4px solid #c4c4c4;
    color : white;
    font-weight : bold;
    
}

.statistic-table td{
    vertical-align: middle;
    padding-left : 20px;
    border-top : unset;
}

.statistic-table th{
    position: sticky;
    top: 0;
    height : 60px;
    border : none;
    background :#c4c4c4;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
}

.statistic-table .score-cell{
    text-align : center;
    padding-left : unset;
}

.statistic-table .green-cell{
    background : #5db481;
}

.statistic-table .red-cell{
    background : #bf4d4d;
}

.statistic-table .blue-cell{
    background : #26547e;
}

.score-info-div{
    display: inline-block;
    width: 350px;
    height: 250px;
    background: #252629;
    padding: 8px;
    margin-left: 20px;
}

.score-info-div div{
    max-width : 50%;
    display : inline-block;
    height : 100%;
    min-width : 50%;
    vertical-align: middle;
}

.score-info-div .right-answers, .wrong-anwswers{
    font-size: 40px;
    text-align: center;
    vertical-align: middle;
    padding: 10px;
    background: #5db481;
    font-weight: bold;
    line-height: 234px;
}

.score-info-div .wrong-anwswers{
    background : #bf4d4d !important;
}

.file-popup #add-directory{
    background : white;
}

.file-popup #add-directory input{
    border-color : black;
    color : black;
}

.clickable {
    cursor : grabbing;
}

.bonus-modal input{
    height : 40px;
}