

.admin-welcome-body {
    background :url("../images/welcome.jpg");
   
     font-family:  'Work Sans';
     background-size: cover;
     color : white;
     
     
}




#welcome #left-side{
   
    display : inline-block;
    float : left;
   
    
}

#welcome #right-side{
  
    display:inline-block;
  
    margin : 0 auto;
    float : right;
    text-align :center;
   
    
}

#left-side p{
    font-size :66px;
    margin-top : 50%;
   
    
    
}


