@import "~react-image-gallery/styles/css/image-gallery.css";

body{
    background-color :#F7EEEB;

}

html {
    /*max-height: 100vh;*/
    height : 100%;
}

@keyframes fadein {
    from { opacity: 0}
    50% { opacity: 0.5}
    to   { opacity: 1}
}

@keyframes slidein {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0%);
    }
}

.container-fluid .row{
    margin-right : unset !important;
    margin-left : unset !important;
}
#module-name{
    word-wrap: break-word;
    max-width : 230px;
    margin : 0 auto;
}

.ages_div{
    background : url("../images/age_select.png");
    min-height: 400px;
    text-align: center;
    color : white;

    padding-top : 80px;
    position : relative;
    top : 50px;
    background-size : 100% auto;
    padding-bottom :20px;
    background-repeat: no-repeat;
    transition: all 1s ease-out;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}




.inactive_ages_div{
    background : url("../images/inactive_age_select.png");
    background-size : contain;
    background-repeat: no-repeat;

    transition: all 1s ease-in;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.ages_div p {
    margin : 0 auto;
    font-size : 40px;
    padding :25px;
}

.ages_div .age-border {
    border-bottom : 2px solid white;
    max-width : 180px;
    margin : 0 auto;
}

.ages_button {
    /* padding:5px;*/
    position : relative;


}

.ages_button .theme_button {
    margin-top : 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}


.ages_button_not_display {
    display:none;
}
.ages_button_display{
    display: inherit;

}

.ages_button_display a{
    width :0;
    animation: slidein 1s ease-out;

}


.ages_button a {
    text-align: center;
    display : inherit;
    padding-top : 19fpx;
    width : 100%;

}

.ages_button a:hover{
    text-decoration: none;
    color : white;
}

.active_age{
    top : 0px;
}

.age_con{
    background-image : url("../images/age_con.png");
    background-repeat: no-repeat;
    background-position-x : 300px;
    background-position-y : center;
}

.age_cancel{
    background-image : url("../images/age_cancel.png");
    background-repeat: no-repeat;
    background-position-x : 300px;
    background-position-y : center;
}
.muzped_back{
    background : url("../images/muzped_background.png");
    background-size : fill;
    background-repeat : no-repeat;
    min-height: 100vh;
    height : 100%;
}

.theme_back{
    background : url("../images/theme_background.png");
    background-size : cover;
    background-repeat : no-repeat;
}

.module_back{
    background : url("../images/theme_background.png");
    background-size : cover;
    background-repeat : no-repeat;
}

.module_back h3{
    font-size:19px;
    font-weight: bold;
}

.theme-list-div .element-with-background-image{
    -webkit-mask-image: url("../images/theme_list_select.png");;
    mask-image: url("../images/theme_list_select.png");
}


.theme_list_div{
    background : url("../images/theme_list_select.png");
    min-height: 300px;
    text-align: left;
    color : white;
    font-weight: bold;
    padding-top : 80px;
    position : relative;

    background-size : cover;

    display:inline-block;
    opacity: 0.7;
    padding: 80px;
    margin-left: -30px;
    margin-top: -30px;
}

.theme_list_img_div{
    height: 300px;
    margin-top: 50px;
    margin-left:30px;
}

.theme_list_div p{
    margin-top: 160px;
    margin-left: 20px;
    text-transform: uppercase;
    font-size: 18px;
}

.theme-list-div .theme_title{
    z-index : 2;
    padding : 20px;
    text-transform: uppercase;
    padding-bottom : 0;
}

@-webkit-keyframes slide-in-bck-center {
    0% {
        -webkit-transform: translateZ(600px);
        transform: translateZ(600px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}
@keyframes slide-in-bck-center {
    0% {
        -webkit-transform: translateZ(600px);
        transform: translateZ(600px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}


.element-with-background-image{

    height: 88%;
    text-align: left;
    color : white;
    font-weight: bold;


    width : 100%;
    -webkit-mask-image: url("../images/theme_preview.png");;
    mask-image: url("../images/theme_preview.png");
    mask-size:100% 100%;
    mask-repeat : no-repeat;
    -webkit-animation: slide-in-bck-center 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bck-center 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}



.theme_select_div .element-with-background-image img{
    height : 100%;
    width : 100%;
    position: absolute;
    object-fit : cover;
}


.theme_select_div .element-with-background-image {
    height : 100%;
    width : 100%;
    position : relative;
}

.theme_select_div .element-with-background-image p{

    position : relative;
    font-size : 20px;

}

.theme_select_div .color-overlay {
    height : 100% !important;
    position : unset;
    padding : 90px;
    position : relative;

}

.theme_select_div .element-with-background-image::before {
    min-height : 100%;
    height: 100% !important;




}

.theme_select_description {

    white-space: pre-wrap;
    position : absolute;
    display : inline;
    bottom : 0;
    max-height : 60%;
    overflow: auto;
}

.theme_select_div .theme_select_description p{
    word-wrap:  break-word;
    overflow :hidden;
    width : 100%;
    height:150px;
    font-size:24px;
    vertical-align: bottom;
    display: inline;

}



.theme_select_div .theme_select_description .theme_title{
    font-size:24px;


}

.theme_select_div .element-with-background-image .theme_title{
    font-size:35px;
    text-transform: uppercase;
    font-weight: bold;
}

.theme_select_div theme_select_description .theme_description{
    font-size:20px;
}

.theme_select_div .theme_button{
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
}


.theme_button{
    min-width: 250px;
    height: 70px;
    color: white;

    border: none;
    text-transform: uppercase;
    font-size: 18px;

    margin-top : 30px;
    padding : 25px;
    padding-top : 20px;
    text-align: center;
    align-content: center;
    vertical-align: middle;
    font-weight : bold;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.theme_select_div a{
    /* margin-left : 300px;*/
}

.theme_green_button{
    background-color: #36BAA2;
}

.theme_grey_button{
    background-color :#6d6d6d;
    box-shadow: inset 2px 2px 4px 4px #676767 !important;
    filter : unset !important;
}
.theme_select_div .col{
    /*margin-top : 50px;*/
}


.theme_back h3 {
    text-transform: uppercase;
    font-weight : bold;
    font-size : 20px;

}

.theme_back p{
    /* margin-top : 190px;*/
    font-size:19px;
}

.btn-backward{
    background-image : url("../images/backward.png");
    background-size : 52px auto;
    width: 50px;
    height: 53px;
    background-repeat: no-repeat;
}

.btn-home{
    background-image : url("../images/home.png");
    background-size : 52px auto;
    width: 52px;
    height: 53px;
    background-repeat: no-repeat;
}

.btn-forward{
    background-image : url("../images/forward.png");
    width: 60px;
    height: 53px;
    background-repeat: no-repeat;
    margin-left : 15px;
}

.btn-module{
    background-image : url("../images/moduls_button.png");
    background-size : 52px auto;
    width: 60px;
    height: 53px;
    background-repeat: no-repeat;
    margin-left : 40px;
}

.black_border{
    padding : 5px;
    border : 3px solid black;
    font-size:17px;
    height:40px;
    vertical-align: middle;
    margin : 0;


}
.answer-sign{
    width: 80px;

    padding: unset;
    position: absolute;

    left: 0;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    border : 5px solid black;
    border-top: unset;

    border-bottom: unset;
    z-index:-1;
}
#quiz_container{
    margin-top : 150px;
}

.endquiz {
    margin-top : unset !important;
}

#quiz_container .black_border{
    font-size:31px;
    margin-bottom:20px;
    min-height:80px;

    height : auto;
    padding: unset;

    position: relative;

    padding-left: 95px;
    text-align: left;

    border: 5px solid black;
}

.quiz_answear {
    width: calc(100% - 50px);
    /*margin-right: 70px;*/
    /*   border-left: none;*/
    text-align : center;
    display: flex;
    align-items: center;
    border-left : unset !important;
}

.quiz_right_answer .answer-sign{
    background-color : #36BAA2 !important;
    color : white !important;
}

.quiz_wrong_answer .answer-sign{
    background-color : red !important;
    color : white !important;
}



#overlayWrapper {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    position: fixed; 
}

#overlay {
    background: rgba(0,0,0,0.7);
    position: absolute;
    height: 99%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

/*.btn-module:hover #overlay {
    opacity: 1;
    
}*/

.theme_list_div .element-with-background-image {
    width: 100%;
    height: 300px;

    -webkit-mask-image: url("../images/theme_list_select.png");;
    mask-image: url("../images/theme_list_select.png");
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
}

.color-overlay {


}

.element-with-background-image::before {
    content :"";
    position: absolute;

    width: 100%;
    height: 300px;
    background-color:#36BAA2;
    z-index:1;
    opacity: 0.7;
}

.element-with-background-image .row{
    z-index:2;
    position:relative;
}



.element-with-background-image{
    width:100%;
    height:300px;


}

.element-with-background-image img{
    filter : grayscale(1);
    width : 100%;
    height:300px;
    object-fit: cover;
}

.element-with-background-image p{

    position: absolute;

    color: white;
    font-size: 16px;
    width : 100%;
    bottom :0;


}


.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.modal-main {
    position:fixed;
    background: black;
    width: 100%;
    height: auto;
    bottom:0;
    top : auto;
    color : white;
    padding : 30px;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

#module-list li {

    background : black;
    margin-bottom : 10px;
    border-radius : unset;
    opacity : 0.8;
    color : grey;
    padding :0;
    padding-bottom: 3px;
    padding-top : 7px;
    width: 940px;  
    height : 75px;
}

#module-list .active-module{

    opacity : 1;
    color : white;
}


#module-list li div{

    height : 50px;
    border: 3px solid grey;
    padding : 10px;
    display : inline-block;
    border-right :none;
    cursor : grabbing;
    vertical-align : top;



}

#module-list .active-module div {
    border: 3px solid #36BAA2;
    border-right :none;
}

#module-list .active-module .eye{
    background : url('../images/active-module-eye.png')#36BAA2;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor : default;


}

#module-list li .eye{
    background : url('../images/inactive-module-eye.png')grey;

    background-repeat: no-repeat;
    height: 50px;

    display: inline-block;

    background-size: contain;
    background-position: center;
    margin-left: 15px;
    cursor : default;


}


.dragunder{
    border-bottom: 3px solid white;
}

#module-list  .dragabove{
    border-top: 3px solid white;
}

#module-list .list-group-item + .list-group-item {
    border-top-width: 3px;
    border-bottom-width: 3px;
}

#module-list .list-group-item{
    border-top-width: 3px;
    border-bottom-width: 3px;
}
#module-list .eye {
    padding-right : 25px;
    cursor : pointer;
}

#module-list li  .module-list-name{
    width : 500px;
    border-right : 3px solid;
}

#module-list .active-module .module-list-name{
    border-right: 3px solid #36BAA2;
}

.game-pictures{
    position : relative;
    margin-left : 20px;
    margin-bottom : 20px;
}

.endpair .game-pictures{
    /* vertical-align: top;
     display: inline-block;
     height: 330px;*/

}

.endpair {
    /*  margin-right : 10px;*/
}
.label-name-changed{
    margin-left : 20%;
}

#pairing_container .drag-over {
    border : 3px dotted;
}

#game-container {
    margin : 0 auto;

    min-height: 100%;
}

#good_pairs #paired_images.game-pictures{
    margin-bottom : unset !important;
}
.pairing_game_container{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/*.pairing_game_container .game-pictures:nth-child(1){
    top : -20px;
    position:relative;
}

.pairing_game_container .game-pictures:nth-child(2){
    top : 20px;
    position:relative;
    left : 20px
}

.pairing_game_container .game-pictures:nth-child(3){
    top : -30px;
    position:relative;
}
.pairing_game_container .game-pictures:nth-child(4){
    top : -40px;
    position:relative;
    left : 30px;
}
.pairing_game_container .game-pictures:nth-child(5){
    top : 175px;
    position:relative;
    left : -60px;
}

.pairing_game_container .game-pictures:nth-child(6){
    top : 60px;
    position:relative;
    left : 140px;

}
*/


#good_pairs{
    position: absolute;
    /* top: 220px;*/
    right: 10px;
    z-index : -1;

}

#good_pairs img{
    width :90px;
    height : 90px;
    margin :0;
    object-fit: cover;
    object-position: top;
}




#circle{
    height:100px;
    width:100px;

    border-radius: 4rem;

    opacity : 0.8;
    -webkit-animation: pulsate 1000ms ease-out;
    -webkit-animation-iteration-count: infinite; 

    -webkit-transition: background-color 300ms linear;
    -moz-transition: background-color 300ms linear;
    -o-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
    position: absolute;
    top: 0;
    left: 5vw;
    z-index: 1000;
}

@-webkit-keyframes pulsate {
    0% {opacity: 0.1;}
    40% {opacity: 1;}
    60% {opacity: 1;}
    100% {opacity: 0.1;}
}

.red_circle{
    background-color : red;
}

.green_circle{
    background-color : #36BAA2;
}

.label{
    position:relative;
    /* top :30px;
     left : 30px;*/
    color : white;
    font-weight: bold;
    width:10vw;
    min-height: 2vw;
}

.label .label-number{
    background: gray;

    width: 20%;
    text-align: center;
    font-size: 1vw;
    min-height: 20%;
    vertical-align: top;
    display : inline-block;
    height : 100%;
    position : absolute;
}

.label .label-number .label-number-flex{
    display : flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.label .label-name{
    background: gray;
    /* padding-top: 14px;
     padding-bottom: 15px;*/
    padding : 10px;
    width: 80%;
    padding-left : 10px;

    font-size: 1vw;
     min-height: 50px;
    margin-bottom : unset;

}

#good_pairs .label{
    display :none;
}


.game-pictures img{
    width: 10vw;
    height: 10vw;
    object-fit : cover;
    object-position: top;

}
#game-container #pairing_container{
    width: calc((4 * 10vw) + 80px);
}
#pairing_container{
   
    margin: 0 auto !important;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    justify-content: center;
    text-align: left;
    padding-top : 35px;
    overscroll-behavior: none;
   
}

.pairing-drag-div {
    display:inline-block;
}

.pairing-drag-div img{


}
#good-pairs .pairing-drag-div{
    display:inline-block;
}

.pair {
    border-radius : 0 0 120px 120px;
}

.gameend-body::before{
    content: attr(data-before);
    background-color: #36BAA2;
    opacity: 0.9;
    position: absolute;
    display: inline-block;
    width: 100%;
    min-height: 100%;
    top :0;
    z-index:1;

}

.gameend-body > * {
    z-index: 100;
}
.gameend-img{
    position: absolute;
    top: 0;
    height: 100%;
    object-fit: cover;
    width: 100%;
    z-index: -1;
    filter: grayscale(1);
    display:none;
}

.gameend-body {
    color : #F7EEEB;
}

.gameend-body .endanswers .endanswer {

}


.gameend-body .final_question_container_open .endanswer {
    border-right: 3px solid #F7EEEB;

}

.endanswers .first-column {
    text-align : right;
}

.gameend-body .endanswers label{
    border : 3px solid #F7EEEB;
    font-size:22px;

    margin-bottom  : 2px;
}

.final_question_container_open .endanswersign{
    border-bottom: 3px solid #F7EEEB;
}


.gameend-body .endanswers span{

    font-size:24px;
}


.gameend-body .gameend-img{
    display:inherit;
}
.gameend-body {
    height: auto;
}

.endpair img{
    /*  width : 230px;
      height :251px;*/
}
.endpair {
    width : 10vw;
    display:inline-block;
    margin-left : 20px;
    text-align: left;
    line-height : 0;
}

.endpair .label{
    line-height : 1;
}

.endpairs{
    /* text-align: center;*/
    margin: 0 auto;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-content: center;
   /* display: flex;*/
}

.endlabel {
    position:absolute;
    margin-left : 5px;
    color : white;
}

.endlabel .label-number{
    background: gray;
    padding-top: 14px;
    padding-bottom: 15px;
    width: 30px;
    text-align: center;
}

.endlabel .label-name{
    background: gray;
    padding-top: 14px;
    padding-bottom: 15px;
    width: 220px;
    padding-left : 10px;


}

.pair-label{

}

.endpair .pair {

}

.endanswers label{
    display: inline-block;

    padding: 10px;
    border: 3px solid black;
    font-weight: bold;
    width : 42px;
    text-align : center;

}

.endanswers .endanswer{

}

.playground {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    perspective: 1000px;
    margin-bottom: -15px;
    width : 100%;

}




.playground .memory-card {
    width: calc((100% / 6) - 25px);
    user-select: none;
    height: 180px;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    margin-bottom:95px;
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
    /*  margin-left : 35px;*/
    height : 200px;

}

.playground .memory-card div {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 250px;
    border-radius: 10px;
    transition: 0.6s;

    //background: #f4f5f7; 
}

.playground .memory-card .front {
    background-image : url("../images/memory_card_back.png");
    font-size: 50px;
    line-height: 120px;
    cursor: pointer;
    color: darken(#f4f5f7,20%);
    display:flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;



}

.playground .memory-card .back {
    transform: rotateY(180deg);
    line-height: 110px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.playground .memory-card .back   img {

    height : 250px;
    width : 100%;
    object-fit: cover;
    /*padding-left : 30px;
    padding-right:30px;*/
}

.playground .memory-card .front   img {
    vertical-align: middle;
    height : 250px;
    width : 100%;
    object-fit: cover;
    /*  padding-left : 30px;
      padding-right:30px;*/
}

.playground .memory-card .front   label {
    position: absolute;
    top: 0px;
    left: 0;
    font-size: 22px;
    padding: 10px;
    background: gray;
    line-height: 1;
    color : white;
    font-weight: bold;
}

.playground .opened {
    transform: rotateY(180deg);
}

.playground .matched {
    transform: rotateY(180deg);
    visibility: hidden;
}
.playground .matched   .back {
    box-shadow: 0 0 0 2px rgba(#000,.05) inset;
    /*  animation: selected .8s 0s ease 1;
      animation-fill-mode: both;
      opacity: .2;*/
}

.memory-end-pic{
    display:inline-block;
    margin-left : 20px;
}

.memory-end-pic img{
    width : 300px;
    height : 350px;
    object-fit :cover;
}

.memory-end-pic .label-name{
    width :300px;
    font-size : 22px;
}

.memory-end-pic .endlabel{
    margin-left :0;
    bottom : -10px;
}

@keyframes selected {
    0% {
        opacity:.2;
    }
    30% {
        opacity:.5;
    }
    50% {
        opacity:.9;
    }
    70% {
        opacity:.2;
    }
    100% {
        opacity:.3;
    }
}


.modal-button{
    min-width: 150px;
    height: 50px;
    color: white;
    border: none;
    text-transform: uppercase;
    font-size: 12px;

    padding: 15px;
    text-align: center;
    align-content: center;
    vertical-align: middle;
    margin-right : 20px;

}

.description-image{
    position: absolute;
    width: 100%;
    height: calc(100% - 150px);
    top: 0;
    left: 0;
    z-index: -1;
    object-fit : cover;
}

.description-head::before{
    background: #F7EEEB;

    top :0;
    width : 100%;
    height : 150px;
    left : 0;
    position:absolute;
    content : "";
}

.description-text::before{
    background: #F7EEEB;

    top :500px;
    width : 100%;
    height : calc(100% - 500px);
    left : 0;
    position:absolute;
    content : "";
}

.description-text p::-webkit-scrollbar {
    width: 7px;               /* width of the entire scrollbar */
}
.description-text p::-webkit-scrollbar-track {
    background: grey;        /* color of the tracking area */
}
.description-text p::-webkit-scrollbar-thumb {
    background-color: black;    /* color of the scroll thumb */
    border-radius: none;       /* roundness of the scroll thumb */
    border: 2px solid black;  /* creates padding around scroll thumb */
}

.description-text p{
    scrollbar-width : thin;
    scrollbar-color : black grey;
    font-size:28px;
}

.theme_select_description::-webkit-scrollbar {
    width: 7px;               /* width of the entire scrollbar */
}
.theme_select_description::-webkit-scrollbar-track {
    background: grey;        /* color of the tracking area */
}
.theme_select_description::-webkit-scrollbar-thumb {
    background-color: black;    /* color of the scroll thumb */
    border-radius: none;       /* roundness of the scroll thumb */
    border: 2px solid grey;  /* creates padding around scroll thumb */
}

.theme_select_description  {
    scrollbar-width : thin;
    scrollbar-color : grey grey;

}

.muzped-footer {
    /* margin-top: 250px;
     text-align: left;
     padding-left: 20px;*/
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height:0px;
    display : inline-table;
    min-height: 6%;
    max-height: 10%;
    padding-bottom : 10px;
    z-index:100;
}

.muzped-footer .container{
    /*margin-bottom : 40px;*/
    margin-top : unset !important;
}


.muzped-footer:before
{
    content:"";
    display:table;
    line-height:0;
}
.muzped-footer:after {
    clear:both;
}

.endanswers {
    max-height : 650px;

    overflow : hidden;
}
.endanswers .columns{
    width : 50%;
    display : inline-block;
    height : 700px;
    overflow : hidden;
    padding-right : 40px;
    padding-left : 40px;
}

.endanswers .second-column .final_question_container_open span {
    text-align : left;
    padding-left : 5px;
}



.final_question_container_closed span{
    display:none;
}

.final_question_container {
    margin-bottom :10px;
}

.final_question_container_open span{
    display:inline-block;
    width:680px;
    font-weight: bold;
    text-align: right;
    padding-right: 5px;
    word-wrap: break-word;
    vertical-align: middle;

}


.final_question_container_open .endanswer{
    border-right: 3px solid #F7EEEB;
    border-bottom : 3px solid #F7EEEB;
}

#final-quiz-button{

    height: 50px;
    width: 50px;
    font-size: 25px;
    border-radius: unset;
    border : 3px solid white;
}
.gameend-body .btn{
    color : #F7EEEB;
}
.gameend-body .final-quiz-button-open{
    background : white;
    mix-blend-mode: screen;
    color : black;
}

.gameend-body .black_border{
    border : 3px solid #F7EEEB;
}

.gameend-body .btn-home{
    background-image : url("../images/white_home.png");
}

.gameend-body .btn-module{
    background-image : url("../images/white_modul.png");
}


.theme-list-div{
    max-height: 780px;
    overflow: auto;
    scrollbar-width: none;
    overflow-x : hidden;
}

.image-gallery-content, .image-gallery-slide .image-gallery-image,.image-gallery, .image-gallery-content,.image-gallery-slide-wrapper,.image-gallery-swipe{

    height: 100% !important;
    cursor : default;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 100% !important;
}


.image-gallery-slides{
    height:100%;
    /*  position: absolute;
      height: 100%;
      width: 100%;*/
}

.image-gallery-slide,.image-gallery-slide div{
    /*  position : relative;
      height : 100%;
      width : 100%;*/
    height :100% !important;
}

#image-module-container {
    margin: 0 auto;
    width: 90% !important;
    height: calc(100vh - 220px) !important;
}

.muzped-icon{
    height: 40px; 
    width: 47px;
    background-image : url("../images/muzped_icon.png");
    display: inline-block;
    vertical-align: middle;
    background-size:cover;
    background-repeat : no-repeat;
}

.gameend-body .muzped-icon {
    background-image : url("../images/white_muzped_icon.png");
}

.image-gallery-slide .image-gallery-image {
    /*  max-width: 100%;
      object-fit: contain;*/
}

.image-gallery-slide{
    /*  position : relative !important;*/
}

.muzped-text{
    height: 40px; 
    width: 101px;
    background-image : url("../images/muzped_logo_text.png");
    display: inline-block;
    vertical-align: middle;
    background-size:contain;
    background-repeat : no-repeat;
}

.gameend-body .muzped-text{
    background-image : url("../images/white_muzped_text.png");

}

.gameend-body .btn-forward{
    background-image : url("../images/white_forward.png");
}

.gameend-body .btn-backward{
    background-image : url("../images/white_backward.png");
}

.btn-logout{
    background-image : url("../images/logout_black.png");
    background-size: 40px auto;
    width: 47px;
    height: 40px;
    background-repeat: no-repeat;
    margin-top: -3px;
    border: unset;
    margin-right: 0;
    vertical-align: middle;
    background-position: right;
}

.gameend-body .btn-logout{
    background-image : url("../images/logout_white.png");

}

.gameend-back{
    color : #F7EEEB;
}


.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 30px;
    width: 60px;
    margin-left: 300px;
    z-index: 7;
    position: relative;
}

.image-gallery-bullets {
    bottom: -70px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 400px;
    z-index: 0;
    height: 50px;

    vertical-align: middle;

    padding-top: 20px;
}

.image-gallery-left-nav {
    background-image : url("../images/backward.png");
    width: 60px;
    height: 53px;
    background-repeat: no-repeat;
    margin-left : -60px;
}
.image-gallery-right-nav{
    background-image : url("../images/forward.png");
    width: 60px;
    height: 53px;
    background-repeat: no-repeat;
}

.image-gallery-left-nav svg, .image-gallery-right-nav svg{

    display : none

}

.image-gallery-right-nav {
    margin-right : -60px;
}


.img-comp-container {
    position: relative;
    height: 100%;
    display : none;
    width : 100%;
}

.img-comp-img {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow:hidden;
}

.img-comp-img img {
    display:block;
    vertical-align:middle;
    object-fit : cover;
}

.img-comp-slider {
    position: absolute;
    z-index:9;
    cursor: ew-resize;
    /*set the appearance of the slider:*/
    width: 40px;
    height: 40px;
    background-color: white;
    opacity: 0.7;
    border-radius: 50%;
}

#module-container .image-gallery-bullets  .image-gallery-bullet{
    background : transparent;
    border: 1px solid black;
    box-shadow: none;
}

#module-container .image-gallery-bullets  .active{
    background : black;

}

.image-gallery-icon{
    filter : none;
}


.img-comp-container.active{
    display : inline-block;
}


.swipe-container .btn{
    margin : 50px;
    vertical-align: middle;
    margin-top : 240px;
}

.inside-label-nubmer-top{
    margin-bottom : -2vw;
}

.inside-label-nubmer-bottom{
    margin-top : -2vw;
}

.inside-label-nubmer-top .label-name, .inside-label-nubmer-bottom .label-name{
    display: none;
}

.pairing_text{

    width: 10vw;
    height: 10vw;
    background: #36BAA2;
    text-align: center;
    color: white;
    padding-top: 3vw;
    border-radius: 0 0 120px 120px;
    font-size:1vw;
    font-weight : bold;
    display:inline-block;
    vertical-align: middle;

}

#good_pairs .pairing_text{
    height : 90px;
    width : 90px;
    padding : unset;
    font-size:12px;
    padding-top : 10px;
    display:inline-block;
    vertical-align: middle;
    margin-right : 10px;
}

#good_pairs .pairing-drag-div{
    display:inline-block;
    vertical-align: middle;
}

.pairing-drag-div{
    vertical-align: middle;
    display:inline-block;
}


.endpair .pairing_text{
    width: 10vw;
    height: 10vw;;

    margin : 0 auto;
    display: inline-block;
    vertical-align: middle;
    line-height: 1.5;
}

.text_pairing_end .label-number{
    padding : unset;
}

.text_pairing_end .pair-label{

}

.remus {
    position: relative;
    display: flex;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    -webkit-user-select:none;
    -moz-user-select: none;
    user-select: none;
    -ms-user-select: none;
    -webkit-user-drag:none;
    -moz-user-drag: none;
    user-drag: none;
    -ms-user-drag: none;
}

.remus > * {
    width: 100%;
}

.remus .remus-element {
    width: 100%;
    overflow: hidden;
    display: inline-block;
}

.remus .remus-element > * {
    position: relative;
}

.remus > :first-child {
    position: relative;
}

.remus :not(:first-child) {
    position: absolute;
    top: 0;
    left: 0;
}

.remus .remus-handle {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    background-color: white;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    transition: box-shadow 0.2s cubic-bezier(.25,.8,.25,1);
}

.remus .remus-handle:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.remus .remus-handle:active {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.swipe-label{
    position: absolute;

    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: bold;
    bottom : -70px;
}


.multi-swipe-container .img-comp-img img{
    object-fit: contain;
}

#quiz_container .quiz_question{
    padding: 10px;
    margin-bottom : 110px;
}

.dragunder-touch{
    border-bottom: 3px solid white;

}

#module-list  .dragabove-touch{
    border-top: 3px solid white;

}

#module-container {
    /*margin-top : 110px;*/
   /* min-height : calc(100% - 105px);*/
    /* max-height : calc(100% - 105px);*/
  /*  position: relative;*/
    min-height: calc(100% - 175px);
    position: relative;
    margin-top: 20px;

}

.swipe-container{
    position : relative;
    width : 100%;
    height: 100%;
    display : inherit;

}

.pairingmemory-end-pic img{
    width: 12vw;
    height: 12vw;

}

.paringmemory-end-pic .endlabel .label-name{
    max-width : 80% !important;  
    margin-left : -5px;
}

.pairingmemory-end-pic .endlabel{
    bottom : -50px;
    margin-left : -0px;
}

.theme-selection-button-div {
    position: absolute; 
    bottom: 0px;
    right: 0px
}

.module-number{
    border-top: unset;

    width: 47px;
    text-align: center;

}

.module-number b{

    border-right: 2px solid white;
    padding-right: 5px;
    margin-right: 5px;
}

.quiz_buttons{

    margin-top : unset !important;
    height : 53px;
    padding : 6px;
    width : 150px;
    margin-right : 5px;
}

.congratulation-text{
    font-weight : bold;
    margin-top : 210px;
    font-size : 63px;
}

.goto-modules{
    margin : 0 auto;
    height : 53px;
    padding-top : 13px;
    font-weight : unset;
}

.picturequiz-img{

    /* margin-top: -50px; */
    position: fixed;
    top: 0;
    /* top: -300px; */
    left: 0;
    height: 100%;
    bottom: 45px;
    object-fit: contain;
    width: 49%;
    z-index: -1;
    object-position: left;
}

.swipe-images{
    margin : 0 auto;
    width : 100%;
    height : 100%;
    display : inline-block;
    position : relative;
}

.img-comp-container img{
    width :100%;
    height: 100%;
}

#game-container .quiz_right_answer{
    /*  background-color : #F7EEEB !important;
      color : black;*/
}

#game-container .quiz_right_answer .answer-sign{
    /*border-left : 5px solid #F7EEEB;
     border-right : 5px solid #F7EEEB;*/
}

.row-mobile {
    display : none;
}


/* Customize the label (the container) */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 55px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    max-width:  410px;
    color : white;
    text-align: left;
    margin-top : 25px;
    margin-bottom : 25px;

}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: transparent; /*ez a színe a teljes kitöltésnek*/
    border : 2px solid #CA9E67;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
    background-color: #CA9E67;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
    background-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
    background-color : transparent;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #CA9E67; /*ez a színe a pipának*/
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
} 

.help-block{
    color : #ba4e4e;
}

.login-body a{
    color : white;
}

.quizpictureanswers .answer-sign{
    border-right: unset !important;
}

.quizpictureanswers img{
    width : 200px;
    height : 200px;
    margin : 0 auto;
}

.quizpictureanswers .answer-sign{
    width : 200px;
    background : white;
    height : 30px;
    margin  : 0 auto;
    bottom : 0;
    left : auto;
    color : #0E4A7D;
}

.quizpictureanswers .quiz_answear{
    justify-content: center;
}

.quiz_comment{
    background: #ca9d66;
    padding: 20px;
    position: fixed;
    width: 0;
    left: -10px;
    width: 50%;
    bottom: -20px;
    font-size: 15px;
    height: 200px;

    text-align: center;

    display: flex;
    vertical-align: middle;

    justify-content: center;
    flex-direction: column;
    font-weight: bold;
}

#forward_module_button.black_border{
    border-color : white;
    background : transparent;
    text-transform: uppercase;
    color : white;
    outline : none;
}

.simple_quiz_comment{
    font-size: 25px;
    border: 2px solid white;
    height: auto;
    display: inline-block;
    justify-content: center;
    vertical-align: middle;
    flex-direction: column;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    width : calc(100% - 30px)
}
.simple_quiz_comment p{
    display : flex;
    align-items: center;
    justify-content: center;
    width : 100%;
    height: 100%;
}


.simple_comment_sign{

    display: inline-block;
    width: 30px;
    height: 30px;
    border: 2px solid white;
    border-right : unset;
    vertical-align: top;
    line-height: 30px;
}

.picturequiz .quiz_answear{
    width : 100%;

}

.picturequiz .quiz_question {
    margin-bottom : 20px !important;
}

.quiz_question {
    text-align : center !important;
}

.endpicturequiz-img{
    filter : brightness(30%);
}


.endpicturequiz .simple_comment_sign{
    float : left ;
    margin-top : -25px;
    border-top : unset;
    border-right : 2px solid white;
}


.endpicturequiz .simple_quiz_comment{
    width : 500px;
    /* float :left;*/
    padding : 30px;
    display:block;

}

.module-number-online{
    height : 40px !important;
    border-right : unset !important;
    cursor : unset;
    width : auto !important;
    min-width: 48px;
    line-height: 40px;
    padding-top : unset !important;
    padding-bottom : unset !important;
    padding-left : 20px !important;
    padding-right : 20px !important;
    vertical-align: middle;
}

.module-number-online span{

    font-weight: normal;
}

.end-module-scores{

    height : 230px;
    width : 230px;
    padding : 50px;
    font-size : 32px;
    border : 3px solid white;
    margin : 0 auto;
    background : #22ac93;
    margin-top : 40px;
}

.end-module-scores label{

    font-size : 45px;
    width : 100%;
}

.end-module-scores .totalscore{
    font-size : 25px;
    border-top : 2px solid white;
    padding-top : 20px;
    font-weight: normal;
}

.end-online-game .label-div{
    width: 230px;

    margin: 0 auto;
    border: 3px solid white;
    border-top: unset;
    font-size: 15px;
    font-weight: normal;
    height: 30px;
    line-height: 30px;

}

.end-online-game p{
    max-width: 420px;
    font-weight : normal;

    margin : 0 auto;
    margin-top : 40px;

}

.picture_quiz_next_button{
    position : fixed;
    margin-left: calc( (50% + 25%) - 141px);
    left :0;

}

.registration-body .help-block{
    color : white;
    background-color : #ba4e4e;
    width : 405px;
    padding : 30px;
    font-size : 13px;
    text-align: left;
    margin : 0 auto;
    margin-bottom : 70px;
    height : 100px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background-image : url("../images/felkialto.png");
    background-repeat : no-repeat;
    background-position : 50px center;
    padding-left : 120px;
    padding-right : 50px;

}

.registration-body #login_label{
    margin-bottom : 70px;
}

.login-body .error_reg .muzped-input{
    background : #ba4e4e !important;
    color : white !important;
    border-color : #ba4e4e !important;
}

.login-body .error_reg .muzped-input::placeholder{

    color : white !important;

}

.error_reg .checkmark{
    background-color : #ba4e4e;;
    border-color : #ba4e4e;;
}

.logo-border{
    border-left: 4px solid white;
    display: inline-block;
    margin-left: 45px;
    margin-right: 50px;
    vertical-align: baseline;
    padding-top: 50px;
}

.registration-body .muzped-input:valid{
    background-color :#CA9E67;
    color : white !important;
}

.muzped-input:-webkit-autofill,
.muzped-input:-webkit-autofill:hover, 
.muzped-input:-webkit-autofill:focus{
    background-color :#CA9E67 !important;
}


.game-container-littleQuiz .image-div
{
    background : url("../images/castle.jpg");
    /* width : 1200px;
     height:   765px; */
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center center;
    margin : 0 auto
}





.linkbutton{
    padding-left : 30px;
    padding-right : 30px;
    height : 60px;
    line-height: 60px;
    background : #CA9E67;
    color : white;
    font-size : 18px;
    font-weight: bold;
    margin-top : 50px;
    text-transform: uppercase;
    display : inline-block;
}



.mobile-theme-title, .mobile-forward-button, .mobile-close-button {
    display : none;
}


#game-container #quiz_container .black_border{
    font-size : 25px;
}



#game-container #quiz_container .quiz_question {
    padding: 15px;

}

.container{
    position : relative;
}

#game-container{
    /* height: calc(100% - 95px);
     min-height: calc(100% - 95px);
     position: relative;*/

    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    padding-top: 90px
}

#game-container .image-gallery-bullets-container {
    display:none;
}

#game-container #module-middle-footer{
    text-align: right;
}

#game-container .simple_quiz_comment p{
    font-size : 20px;
    font-weight : 500;
    padding : 50px;
    padding-top : 20px;
    padding-left : 20px;
}

#image-module-container {
    margin: 0 auto;
    /*  width: 100% !important;
      height: 100% !important;
      padding-left: 90px;
      padding-right: 90px;
      position: relative;*/
}

.image-gallery{
    /* height : 100%;*/
}

.image-gallery-content, .image-gallery-slide-wrapper, .image-gallery-swipe {
    /*  height : 100%;*/
}



.image-container-div span{
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
    z-index: 20;
    height: 20px;
    left: 0;
    color: black;
    font-weight: bold;
}

.image-container-div .background{

    background-repeat: no-repeat;
    background-size: cover;
    background-position : center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    filter: opacity(0.2);
    z-index: -1;

}

.image-container-div img{
    height : 100%;
}

.header{
    position : absolute;
    z-index : 20;
    width:100%;
    padding:15px;
    right:0;
}

.tour_div{
    width: 100vw;
    height: 100vh;
    margin: 0px auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    text-align: center;

    display: flex;
    justify-content: center;
    align-content: center;
    justify-content: center;
}
#good_pairs #paired_images{
    margin-top: 0px;
    margin-left: 0px;
    display: inherit;
    border: 3px solid white;
    padding: 10px;
    padding-right : unset;
    margin-top : -3px !important;
}

#paired_images img{
    margin-right : 10px;
}
.online-tha-button {
    box-shadow :  rgba(0, 0, 0, 0.2) 0px 0px 10px inset !important;
}
.dashboard-header{
    background-image : url("../images/THA-Nagy-Kviz--Folt-Szia.png");
    position : absolute;
    top : 0;
    width : 100%;
    height : 220px;
    background-repeat: no-repeat;
    background-size : contain;
    text-align: center;
    background-position: center;
    font-size : 22px;
    font-weight : bold;
    padding-top : 20px;

}

.online-dashboard-text{
    margin-top : 80px;
    padding-left : 50px;
    position:absolute;
    bottom : 60px;
    font-size:2vh;
}

.online-dashboard-buttons{
    display : grid;

    justify-content: center;
    align-content: end;
    text-align: center;

}

.module-list-element.dragabove .module-list-element-title, .module-list-element.dragunder .module-list-element-title{
    font-size : 19px;
}

.picture-comment{
    position : absolute;
    bottom : -20px;
    left : 0px;
    width : 290px;
    min-height : 20px;
    background : #CA9E67;
    color : white;
    padding : 10px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    max-height:150px;
    word-wrap: break-word;
    break-after: revert;
    max-width: 290px;

    white-space: pre-wrap;
    line-height: 20px !important;
}

.pairing_game_container{
    margin : 0 auto;
}

.registration-body #left-side{
    margin-left : 80px;
}

#emmet{
    background : url("../images/EMET_logo.png");
    position : fixed;
    bottom : 0;
    left : 0;
    height: 150px;
    width : 300px;
    background-size : contain;
    background-repeat : no-repeat;
    background-position: bottom;
}

#module-type-name{
    font-weight: normal;
}

footer.tour_footer{
    position : absolute !important;
    bottom : 60px;
}

#resend-email input.muzped-input{
    
    color : black !important;
    vertical-align: bottom;
}

#resend-email button.muzped-input{
    width : 70px;
    color : black !important;
    background-image : url("../images/forward.png");
    background-repeat: no-repeat;
}

#resend-email{
    position : absolute;
    margin : 0 auto;
    width:70%;
    text-align: center;
    z-index:100;
}

.unclickable{
    pointer-events:none
}