
.welcome-body {
    background-image :url("../images/background_welcome.png") ;
   
     font-family:  'Work Sans';
     background-color : #F7EEEB;
     background-size:cover;
     
     
}

#welcome #left-side{
  
    display : inline-block;
    float : left;
    padding-left : 50px;
    
}

#welcome #right-side{
  
    display:inline-block;
    padding-left : 30px;
    margin : 0 auto;
   
    text-align :right;
    
    
}

#left-side p{
    font-size :66px;
    margin-top : 50%;
   
    line-height: 80px;
    
    
}



.forgetpassword-body{
     background-image :url("../images/forgetpassword_background.png") ;
   
     font-family:  'Work Sans';
     background-color : #F7EEEB;
     background-size:cover;
}

.forget-password-label{
    color : white;
    width: 350px;
    margin-bottom : 35px;
}