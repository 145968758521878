
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@700&display=swap');

html, body , .root{

    overscroll-behavior-x: none;
    overscroll-behavior: none;
  
   
       
}

html {

    min-height:  100vh !important;
    height : auto !important;
}

#root{

    min-height: 100vh;
    overflow :auto;
    position: relative;
}
.body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background : #343a40;
    font-family:  'Work Sans', sans-serif;

    min-height:  100vh !important;
}


body {
    margin: 0;
    font-family:  'Work Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background : #343a40;


}

.container{
    height:  100vh;
}

.muzped-footer .container {
    height : unset;
}



.module_back{
    font-family:  'Work Sans', sans-serif;
}

.welcome-body{
    font-family:  'Work Sans', sans-serif;
}
.login-body{
    font-family:  'Work Sans', sans-serif;
}

.theme_body{
    font-family:  'Work Sans', sans-serif;
}

.admin-body .wrapper{
    flex-direction: initial;
}

.wrapper{
    display: flex;
    min-height: 98vh;
    flex-direction: column;
    justify-content: space-between;
    height:100%;
    overflow : auto;
}

#content {

    margin : 0 auto;
    position:absolute;
    overflow : auto;
    display:flex;
  
    min-height:  100vh !important;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

}

.container, #game-container{

    font-family:  'Work Sans', sans-serif;
    /*height: 100%;*/
    min-height: 90%;
    padding-top : 20px;


}



.muzped-footer .container{
    padding-top : 0;
}

.muzped-button{

    border : 3px solid black;
    text-transform: uppercase;
    background : transparent;
    font-size : 30px;
    font-weight: bold;
    padding : 15px;

}



.muzped-button:hover{
    background : black;
    color : white;
}

.muzped-link {
    text-decoration: none;
    color : #ffffff;
    font-size : 20px;
    font-weight : bold;
    padding :15px;
    display:inherit;

}

.muzped-black-link {
    text-decoration: none;
    color : black;
    font-size : 20px;
    font-weight : bold;
    padding :15px;
    display:inherit;

}

.muzped-white-border{
    color : #ffffff;
    border : 4px solid #ffffff;
    text-transform: uppercase;
    background : transparent;
    font-size : 20px;
    font-weight: bold;
    padding : 15px;
}

.muzped-black-border{
    color : #ffffff;
    border : 3px solid #ffffff;
    text-transform: uppercase;
    background : transparent;
    font-size : 20px;
    font-weight: bold;
    padding : 15px;
}

.muzped-input{
    background: transparent;
    color: #fff;
    border: 4px solid #fff;
    width: 300px;
    text-align: center;
    font-size:20px;
    /*  padding : 20px;*/
    height:70px;
    /*line-height: 70px;*/

}

.muzped-input::placeholder{
    color : #fff;
}

.muzped-white-button{
    background : #fff;
    padding : 20px;
    font-size : 40px;
    border: 5px solid #fff;
    text-align: center;
}

.muzped-material-input{
    background: transparent;
    color: #fff;
    border : none;
    border-bottom: 3px solid #fff;
    width: 130px;
    text-align: left;
    font-size:15px;
    padding : 3px;
    display : inline;
    vertical-align:bottom;
}

.muzped-material-input::placeholder{
    color : #fff;
    font-weight: bold;
}




.material-input-icon{
    padding: 15px;
    padding-top: 7px;
    padding-bottom: 3px;
    background-repeat: no-repeat;
    background-color: white;
    background-position: center;
    vertical-align: middle;
    background-size: contain;

}

.muzped-logo{
    background-image : url("./images/black_logo.png");
    height: 119px;
    width: 176px;

    display: inline-block;
    background-repeat: no-repeat;
    background-size:contain;
}