@font-face{
    font-family:"Caligo W05 Text Italic";
    src:
        url("../fonts/1050e090-6b9b-4494-a4ea-09455d1fadd8.woff2") format("woff2"),
        url("../fonts/8a55286f-8f62-4b69-b045-f6955915864f.woff") format("woff");
}

.akademia-muzpedmuzped_back .container-fluid{
    margin : unset;
    padding : unset;
}
/*littleQuiz elemek*/
#first-little-quiz  #question_9{

    background-image :url('../images/kicsi_kviz_1/9.png');
    background-size: 100% 100%;
}

#first-little-quiz #question_9:hover{

    background-image :url('../images/kicsi_kviz_1/9_hover.png');
    background-size: 100% 100%;
}

#first-little-quiz #question_7{

    background-image :url('../images/kicsi_kviz_1/7.png');
    background-size: 100% 100%;
}

#first-little-quiz #question_7:hover{

    background-image :url('../images/kicsi_kviz_1/7_hover.png');
    background-size: 100% 100%;
}

#first-little-quiz #question_1{

    background-image :url('../images/kicsi_kviz_1/1.png');
    background-size: 100% 100%;
}

#first-little-quiz #question_1:hover{

    background-image :url('../images/kicsi_kviz_1/1_hover.png');
    background-size: 100% 100%;
}

#first-little-quiz #question_2{

    background-image :url('../images/kicsi_kviz_1/2.png');
    background-size: 100% 100%;
}

#first-little-quiz #question_2:hover{

    background-image :url('../images/kicsi_kviz_1/2_hover.png');
    background-size: 100% 100%;
}

#first-little-quiz #question_3{

    background-image :url('../images/kicsi_kviz_1/3.png');
    background-size: 100% 100%;
}

#first-little-quiz #question_3:hover{

    background-image :url('../images/kicsi_kviz_1/3_hover.png');
    background-size: 100% 100%;
}

#first-little-quiz #question_4{

    background-image :url('../images/kicsi_kviz_1/4.png');
    background-size: 100% 100%;
}

#first-little-quiz #question_4:hover{

    background-image :url('../images/kicsi_kviz_1/4_hover.png');
    background-size: 100% 100%;
}

#first-little-quiz #question_5{

    background-image :url('../images/kicsi_kviz_1/5.png');
    background-size: 100% 100%;
}

#first-little-quiz #question_5:hover{

    background-image :url('../images/kicsi_kviz_1/5_hover.png');
    background-size: 100% 100%;
}
#first-little-quiz #question_6{

    background-image :url('../images/kicsi_kviz_1/6.png');
    background-size: 100% 100%;
}

#first-little-quiz #question_6:hover{

    background-image :url('../images/kicsi_kviz_1/6_hover.png');
    background-size: 100% 100%;
}

#first-little-quiz #question_8{

    background-image :url('../images/kicsi_kviz_1/8.png');
    background-size: 100% 100%;
}

#first-little-quiz #question_8:hover{

    background-image :url('../images/kicsi_kviz_1/8_hover.png');
    background-size: 100% 100%;
}

#first-little-quiz #question_0{

    background-image :url('../images/kicsi_kviz_1/10.png');
    background-size: 100% 100%;
}

#first-little-quiz #question_0:hover{

    background-image :url('../images/kicsi_kviz_1/10_hover.png');
    background-size: 100% 100%;
}

#first-little-quiz #question_0.avoid-clicks{

    background-image :url('../images/kicsi_kviz_1/10_wrong.png');
    background-size: 100% 100%;
}
#first-little-quiz #question_1.avoid-clicks{

    background-image :url('../images/kicsi_kviz_1/1_wrong.png');
    background-size: 100% 100%;
}
#first-little-quiz #question_2.avoid-clicks{

    background-image :url('../images/kicsi_kviz_1/2_wrong.png');
    background-size: 100% 100%;
}
#first-little-quiz #question_3.avoid-clicks{

    background-image :url('../images/kicsi_kviz_1/3_wrong.png');
    background-size: 100% 100%;
}
#first-little-quiz #question_3.avoid-clicks{

    background-image :url('../images/kicsi_kviz_1/3_wrong.png');
    background-size: 100% 100%;
}
#first-little-quiz #question_4.avoid-clicks{

    background-image :url('../images/kicsi_kviz_1/4_wrong.png');
    background-size: 100% 100%;
}
#first-little-quiz #question_5.avoid-clicks{

    background-image :url('../images/kicsi_kviz_1/5_wrong.png');
    background-size: 100% 100%;
}
#first-little-quiz #question_6.avoid-clicks{

    background-image :url('../images/kicsi_kviz_1/6_wrong.png');
    background-size: 100% 100%;
}
#first-little-quiz #question_7.avoid-clicks{

    background-image :url('../images/kicsi_kviz_1/7_wrong.png');
    background-size: 100% 100%;
}
#first-little-quiz #question_8.avoid-clicks{

    background-image :url('../images/kicsi_kviz_1/8_wrong.png');
    background-size: 100% 100%;
}
#first-little-quiz #question_9.avoid-clicks{

    background-image :url('../images/kicsi_kviz_1/9_wrong.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_9.avoid-clicks{

    background-image :url('../images/kicsi_kviz_2/udvar_9_wrong.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_9:hover{

    background-image :url('../images/kicsi_kviz_2/udvar_9_hover.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_9{

    background-image :url('../images/kicsi_kviz_2/udvar_9.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_1.avoid-clicks{

    background-image :url('../images/kicsi_kviz_2/udvar_1_wrong.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_1:hover{

    background-image :url('../images/kicsi_kviz_2/udvar_1_hover.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_1{

    background-image :url('../images/kicsi_kviz_2/udvar_1.png');
    background-size: 100% 100%;
}


#second-little-quiz #question_1.avoid-clicks{

    background-image :url('../images/kicsi_kviz_2/udvar_1_wrong.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_2:hover{

    background-image :url('../images/kicsi_kviz_2/udvar_2_hover.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_2{

    background-image :url('../images/kicsi_kviz_2/udvar_2.png');
    background-size: 100% 100%;
}


#second-little-quiz #question_2.avoid-clicks{

    background-image :url('../images/kicsi_kviz_2/udvar_2_wrong.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_3:hover{

    background-image :url('../images/kicsi_kviz_2/udvar_3_hover.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_3{

    background-image :url('../images/kicsi_kviz_2/udvar_3.png');
    background-size: 100% 100%;
}


#second-little-quiz #question_3.avoid-clicks{

    background-image :url('../images/kicsi_kviz_2/udvar_3_wrong.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_4:hover{

    background-image :url('../images/kicsi_kviz_2/udvar_4_hover.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_4{

    background-image :url('../images/kicsi_kviz_2/udvar_4.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_4.avoid-clicks{

    background-image :url('../images/kicsi_kviz_2/udvar_4_wrong.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_5.avoid-clicks{

    background-image :url('../images/kicsi_kviz_2/udvar_5_wrong.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_5:hover{

    background-image :url('../images/kicsi_kviz_2/udvar_5_hover.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_5{

    background-image :url('../images/kicsi_kviz_2/udvar_5.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_6.avoid-clicks{

    background-image :url('../images/kicsi_kviz_2/udvar_6_wrong.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_6:hover{

    background-image :url('../images/kicsi_kviz_2/udvar_6_hover.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_6{

    background-image :url('../images/kicsi_kviz_2/udvar_6.png');
    background-size: 100% 100%;
}


#second-little-quiz #question_7.avoid-clicks{

    background-image :url('../images/kicsi_kviz_2/udvar_7_wrong.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_7:hover{

    background-image :url('../images/kicsi_kviz_2/udvar_7_hover.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_7{

    background-image :url('../images/kicsi_kviz_2/udvar_7.png');
    background-size: 100% 100%;
}


#second-little-quiz #question_8.avoid-clicks{

    background-image :url('../images/kicsi_kviz_2/udvar_8_wrong.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_8:hover{

    background-image :url('../images/kicsi_kviz_2/udvar_8_hover.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_8{

    background-image :url('../images/kicsi_kviz_2/udvar_8.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_0.avoid-clicks{

    background-image :url('../images/kicsi_kviz_2/udvar_0_wrong.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_0:hover{

    background-image :url('../images/kicsi_kviz_2/udvar_0_hover.png');
    background-size: 100% 100%;
}

#second-little-quiz #question_0{

    background-image :url('../images/kicsi_kviz_2/udvar_0.png');
    background-size: 100% 100%;
}
/*littleQuiz elemek*/


.little-popup{
    position: absolute;
    /* top: 50%; */
    width: 100%;
    text-align: center;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    vertical-align: middle;
    padding-top: 15vh;
    font-family: Caligo;
}

.question-witdh-image{
    
    max-width : 56%;
}

.little_button{
    background : url("../images/little_button_bg.png");
    outline : none;

    background-size: 300px 100px;
    background-repeat: no-repeat;
    width: 300px;
    min-height: 100px;
    border: none;
    font-weight: bold;
    margin-top : 30px;

}

.little_forward{
    background : url("../images/little_forward.png");
    outline : none;

    background-size: 200px 80px;
    background-repeat: no-repeat;
    width: 800px;
    height: 80px;
    border: none;

    position : relative;
    margin-left : 200px;
    margin-top :50px;
    background-position: right;
}


.little-popup-body{
    background : url("../images/Felugro_Ablak-pergamen.png");
    width: 800px;
    height : 500px;
    margin: 0 auto;
    background-size: 800px 500px;
    padding: 100px;
    background-position: top;
    color: black;
    padding-left: 150px;
    padding-right: 150px;
    margin : 0 auto;
    text-align : center;
    background-repeat: no-repeat;
    font-weight: bold;
    font-family:'Caligo W05 Text Italic';
    position : relative;
    /*justify-content: center;
display: flex;
display-direction: center;
flex-direction: column;
align-content: center;
align-items: center;*/
}

.little-popup-body-img{
    background-image : url("../images/pergamen_img.png") !important;
    width: 900px;
    height : 500px;
    margin: 0 auto;
    background-size: 900px 500px;
}
.little-popup-body p {
    width : 100%;
    font-family:'Caligo W05 Text Italic';
    margin : 0 auto;
}
.avoid-clicks {
    pointer-events: none;
}

#question_popup label{

    text-align: center;

 /*   padding-left : 30px;
    padding-right : 30px;*/
    font-size : 16px;
}

#question_popup img{
    height: 300px;
    object-fit :  contain;
    width: 100%;
    object-position: center;
}

.question-img-div{
    display: flex;
    align-content: center;
    justify-content: end;
}

#question_popup{


}

#question {
    text-align: center;
    width: 100%;
    height: 200px;
    padding-right : 25px;
}

#question .answer_label {
    background-image : url("../images/question_bg_1.png");
    background-size : 100% 30px;
    width: 100% ;
    height: 30px;
    background-position: center;
    vertical-align: middle;
    padding-top: 5px;
    margin-bottom: 15px;
    background-repeat : no-repeat;
}

.question_label .answer_label{
    padding : 40px !important;
    padding-top : unset !important;
    padding-bottom : unset !important;
    width : 100%;
}

#question .answer_label.right_answer{
    background-image : url("../images/good_answer.png");
}

#question .answer_label.wrong_answer{
    background-image : url("../images/wrong_answer.png");
}

#questionpopup_close{
    background-image : url("../images/Exit.png");
    height: 60px;
    width: 60px;
    background-size: contain;
    background-color: unset;
    border: unset;
    background-repeat: no-repeat;
    right: 10px;
    position: absolute;
    text-align: right;

    bottom: -50px;
}

.little_quiz .container-fluid{
    max-width: 100% !important;
}


@media(max-height : 660px){
   #questionpopup_close { 
    bottom: unset !important;
    top: 0 !important;
     right: -35px !important;
   }
}
@media (max-height : 520px) , (max-width : 950px) {

    
     #question_popup img{
    height: 200px !important;
  
}
    .little-popup-body{
        width : 700px !important;
        height : 310px !important;
        background-size : 700px 310px !important;
        padding-top  : 40px;
        padding-left: 110px;
       padding-right: 100px;
    }
    .little-popup-body p{
        font-size : 12px !important;
    }
    #question .answer_label {
        margin-bottom : 5px !important;
    }
    .little_button{
        background-size: 200px 50px;
        background-repeat: no-repeat;
        width: 200px;
        min-height: 50px;
    }
    .little-popup{
        padding-top : 15vh ;
    }
    .little-popup-body-img{

        
        

    }
    /*  .little-popup-body{
          width : 370px !important;
          height : 410px !important;
          font-size: 10px;
          text-align: center;
          background-size : contain !important;
          padding : unset !important;
          background-repeat : no-repeat !important;
          text-align: center;
          margin: 0 auto;
          font-weight : bold;
          padding-top : 15px !important;
  
      }
  
      .little-popup{
          padding-top : 100px !important;
      }
      .little-popup-body p{
          width : 220px;
          margin : 0 auto;
          margin-bottom : 3px;
      }
      .game-container-littleQuiz{
          margin-top : 100px !important;
      }
  
      #question label{
          width: 200px !important;
          height : 20px !important;
          margin-bottom : unset !important;
          padding-top : unset !important;
      }
  
      #question_popup{
          width: 300px !important;
          height: 300px !important;
      }*/
}

@media(max-height : 365px){
    .little-popup {
    padding-top: 20px !important;
}
}