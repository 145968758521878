
#sidebar-div{
   
         min-height : 100%;
         width:350px;
         display : inline-block;
}

#sidebar {
    padding : 35px;
    
    background-repeat: no-repeat;
    background-size: 350px 100%;
     width: 350px;
     display: inline-table;
     background : #27547e;
     position : fixed;
     top : 0;
}
#sidebar.active {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
   
}

#sidebar ul{
    border-top : 3px solid #fff;
    padding : 10px;

}

#sidebar ul .active {
    font-weight: bold;
}

#sidebar ul li:hover {
    font-weight: bold;
}


#sidebar .sidebar-header strong {
    display: none;
}
#sidebar.active .sidebar-header h3 {
    display: none;
}
#sidebar.active .sidebar-header strong {
    display: block;
}
#sidebar ul li{
    padding : 10px;
}
#sidebar ul li a {
    text-align: left;
    font-size : 18px;
    color : #fff;
    text-transform: uppercase;

}



#sidebar.active ul li a {
    padding: 20px 10px;
    text-align: center;
    font-size: 0.85em;
}

#sidebar.active ul li a i {
    margin-right:  0;
    display: block;
    font-size: 1.8em;
    margin-bottom: 5px;
}


#sidebar.active ul ul a {
    padding: 10px !important;
}


#sidebar.active .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
}


@media (max-width: 768px) {
   
    #sidebar.active {
        min-width: 80px;
        max-width: 80px;
        text-align: center;
        margin-left: -80px !important;
    }


    /* Reappearing the sidebar on toggle button click */
    #sidebar {
        margin-left: 0; 
    }


    /* Toggling the sidebar header content, 
       hide the big heading [h3] and showing the small heading [strong] and vice versa
    */
    #sidebar .sidebar-header strong {
        display: none;
    }
    #sidebar.active .sidebar-header h3 {
        display: none;
    }
    #sidebar.active .sidebar-header strong {
        display: block;
    }

    /* Downsize the navigation links font size */
    #sidebar.active ul li a {
        padding: 20px 10px;
        font-size: 0.85em;
    }

    #sidebar.active ul li a i {
        margin-right:  0;
        display: block;
        font-size: 1.8em;
        margin-bottom: 5px;
    }

    /* Adjust the dropdown links padding*/
    #sidebar.active ul ul a {
        padding: 10px !important;
    }

    /* Changing the arrow position to bottom center position, 
      translateX(50%) works with right: 50% 
      to accurately  center the arrow */
    .dropdown-toggle::after {
        top: auto;
        bottom: 10px;
        right: 50%;
        -webkit-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%);
    }
}

nav {
    height : auto;
}


.fa-theme{
    background: url('../images/admin-menu/01-Ikon-Tematika.png');
    height: 38px;
    width: 38px;
    display: inline-block;
    vertical-align: middle;
    margin-right:45px;

}

.fa-subtheme{
    background: url('../images/admin-menu/02-Ikon-Altematika.png');
    height: 38px;
    width: 38px;
    display: inline-block;
    vertical-align: middle;
    margin-right:45px;

}

.fa-module{
    background: url('../images/admin-menu/03-Ikon-Modul.png');
    height: 38px;
    width: 38px;
    display: inline-block;
    vertical-align: middle;
    margin-right:45px;

}

.fa-media{
    background: url('../images/admin-menu/04-Ikon-Mediatar.png');
    height: 38px;
    width: 38px;
    display: inline-block;
    vertical-align: middle;
    margin-right:45px;

}

.active .fa-theme{
    background : url('../images/admin-menu/aktiv_tematika.png');
    background-size: cover;
}

li:hover .fa-theme{
    background : url('../images/admin-menu/aktiv_tematika.png');
    background-size: cover;
}


.active .fa-subtheme{
    background : url('../images/admin-menu/aktiv_altematika.png');
    background-size: cover;
}

li:hover .fa-subtheme{
    background : url('../images/admin-menu/aktiv_altematika.png');
    background-size: cover;
}

.active .fa-module{
    background : url('../images/admin-menu/aktiv_modul.png');
    background-size: cover;
}

.active .fa-media{
    background : url('../images/admin-menu/aktiv_media.png');
    background-size: cover;
}

.active .fa-history{
    background : url('../images/admin-menu/aktiv_elozmenyek.png');
    background-size: cover;
}

li:hover .fa-module{
    background : url('../images/admin-menu/aktiv_modul.png');
    background-size: cover;
}

li:hover .fa-media{
    background : url('../images/admin-menu/aktiv_media.png');
    background-size: cover;
}

li:hover .fa-history{
    background : url('../images/admin-menu/aktiv_elozmenyek.png');
    background-size: cover;
}

li:hover .fa-forum{
    background : url('../images/admin-menu/aktiv_forum.png');
    background-size: cover;
}


li:hover .fa-logout{
    background : url('../images/admin-menu/aktiv_kilepes.png');
    background-size: cover;
}


li:hover .fa-help{
    background : url('../images/admin-menu/aktiv_sugo.png');
    background-size: cover;
}


li:hover .fa-settings{
    background : url('../images/admin-menu/aktiv_beallitas.png');
    background-size: cover;
}

.fa-forum{
    background: url('../images/admin-menu/05-Ikon-Forum.png');
    height: 38px;
    width: 38px;
    display: inline-block;
    vertical-align: middle;
    margin-right:45px;

}

.fa-history{
    background: url('../images/admin-menu/06-Ikon-Elozmeny.png');
    height: 38px;
    width: 38px;
    display: inline-block;
    vertical-align: middle;
    margin-right:45px;

}

.fa-help{
    background: url('../images/admin-menu/06-Ikon-Sugo.png');
    height: 38px;
    width: 38px;
    display: inline-block;
    vertical-align: middle;
    margin-right:45px;

}

.fa-settings{
    background: url('../images/admin-menu/08-Ikon-Beallitas.png');
    height: 38px;
    width: 38px;
    display: inline-block;
    vertical-align: middle;
    margin-right:45px;

}

.fa-logout{
    background: url('../images/admin-menu/09-Ikon-Kilepes.png');
    height: 38px;
    width: 38px;
    display: inline-block;
    vertical-align: middle;
    margin-right:45px;

}

.profile-img{
    width: 80px;
    height: 80px;
    border-radius: 25px;
    display:inline-block;
    margin : 30px;
}

.fa-forview{
      background: url('../images/forview.png');
    height: 38px;
    width: 38px;
    display: inline-block;
    vertical-align: middle;
    margin-right:45px;
    background-repeat : no-repeat;
    
     margin-right:  0;
    display: inline-block;
    vertical-align: top;
    margin-left : 20px;
    margin-right : 20px;
    
}

.sidebar-header a{
    color : white;
    display:inline-block;
}

