@media  (min-width: 1800px)  {
    .container{
        min-width : 1700px
    }

    .admin-body .container{
        min-width: calc(100% - 420px);
        width: calc(100% - 420px);
    }
}

@media(max-height : 590px ){
    
    #registration-form{
        
        top : unset !important;
    }
    
}
@media (min-width : 840px) and (max-width : 1320px){
    .container{
        min-width : 820px;
    }
   
    .akademia-muzpedmuzped_back .muzped-text{
        display : none;
    }
    
    .ages_div{
        padding-top : 30px !important;
    }
    .ages_div p{
        font-size : 25px !important;
        padding : 5px !important;
    }

    .active_age p{
        padding : 30px !important;
    }

    .ages_div span{
        font-size : 10px !important
    }


    .ages_div .age-border {

        max-width: calc(100% - 20px) !important;
    }

    .theme_select_div .color-overlay{
        height : 500px !important;

    }
    .theme_select_div .element-with-background-image img{
        height : 500px !important;

    }

    .theme_select_div .element-with-background-image::before{
        min-height : 500px !important;

    }
    .theme_select_div .element-with-background-image{
        height : 500px !important;
    }
    .theme_select_div .color-overlay {

        padding: 30px !important;
    }

    .playground .memory-card {
        width: calc((100% / 4) - 25px) !important;
    }

    .playground .memory-card{
        margin-bottom : 70px !important;
    }

    .playground .memory-card .front label{
        top : 0px !important;
        left : 0 !important;
    }

    #module-container {
        margin-top : 30px !important;
    }
}




@media (max-width : 960px){

     #emmet {
        display: none;
    }
    .container {
        /* padding : 5px  !important;*/
        height : auto !important;
    }

    .btn-logout {
        margin-top : -40px !important;
    }
    .header{
        padding-left : 15px ;
        /*margin-top : 20px;*/
    }

    .theme_select_div{
        position: absolute !important;
        bottom : 0px;
    }

    .theme_select_description{
        display : block !important;
        font-size : 12px !important;
        padding : 20px;
        max-height : 250px !important;


    }
    
    .image-container-div img {
   
        max-width: 100% !important;
        object-fit: cover !important;
    }
    #game-container {
        height : auto !important;
    }
    #forward_module_button {
        font-size : 10px !important;
    }

    #game-container {
        height: 100% !important;
    }

    .mobilesubtheme-footer{
        display : none !important;
       
    }
    
    .dashboard-header{
        font-size : 15px !important;
         top : -75px !important;
        padding-top : 76px !important;
    }
    
    .dashboard-header p:nth-child(2){
        margin-top : -20px;
    }
    
    .plus_one span{
        left : 0px !important;
    }
    
    .plus_one {
        padding-left : 40px;
    }
   
    .online-dashboard-text{
        margin-top : 110px !important;
        bottom : unset !important;
    }

    .mobile-close-button{
        background : url("../images/close.png");
        width: 100%;
        display: inline-block !important;
        height: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right top;
        border: none;
        vertical-align: middle;
        margin-right : 15px;
    }

    .mobile-forward-button {
        background : url("../images/white_forward.png");
        width: 30%;
        display: inline-block !important;
        height: 30px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right top;
        border: none;
        vertical-align: middle;
    }

    .mobile-theme-title{
        display : inline-block !important;
        margin-top : 10px;
        font-size : 15px;
        max-width : 50%;
        padding : 15px;
    }

    .color-overlay .align-right , .theme_title {
        display : none !important;
    }


    #login #right-side{
        margin-left : unset !important;
    }
    .col-sm, .col-md-3, .col-md-6,.col-lg{
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .muzped-text{
        display: none !important;
    }
    
    #module-type-name{
        border-right: 2px solid white !important;
    }

    h3{
        font-size:12px !important;
    }

    .muzped-icon{
        height: 30px !important;
        width : 35px !important;
    }

    .col-sm label, .col-lg label{
        font-size: 6px;
        width: 46px;
        height: 30px;
    }

    .black_border {
        border-width: 2px !important;
        padding-left : 20px;
        padding-right : 20px;
    }

    .muzped-text{
        height: 30px !important;
        width: 70px !important;
    }
    .btn-logout{
        background-size: 30px auto !important;
        width: 35px !important;
        height: 30px !important;
    }
    .muzped-footer{
        position : relative !important;
        /* top:620px;*/
    }

    .col {
        flex-basis: unset !important;
    }

    .theme_select_div .theme_select_description p{
        word-break: break-word;
    }

    .theme_select_div .color-overlay{
        padding-left : 10px !important;
        padding-right: 15px !important;
    }

    .theme_select_description{
        position : unset !important;
    }
    .theme-selection-button-div{
        position  : unset !important;
        right : unset !important;
        text-align: center;
    }
    .theme_select_div .color-overlay{
        height : 100% !important;

    }
    .theme_select_div .element-with-background-image img{
        height : 100% !important;

    }

    .theme_select_div .element-with-background-image::before{
        min-height : 100% !important;

    }
    .theme_select_div .element-with-background-image{
        height : 100% !important;
        margin-top : 33px;
    }

    .playground .memory-card{
        width: calc((100% / 3) - 5px ) !important;
        /*  max-height : 110px;
          margin-bottom : unset !important;*/
        margin-left : unset !important;
        margin-bottom : 10px !important;
        max-height: 100px;
        min-height :100px;
    }
    .playground .memory-card div{
        height : 100px !important;
    }
    
    .pairing_game_container label{
       /* font-size : 10px !important;
        top : 0 !important;
        max-width : 300px;*/
    }

    .simple_quiz_comment p{
        font-size : 13px !important
    }

    #quiz_container .quiz_question{
        margin-bottom : 20px !important;
    }
    #module-container{
        margin-top : 10px !important;
        height : auto !important;
        width : 100% !important;
        margin : 0 auto ;
        min-height: calc(90% - 65px);
        max-height: unset !important;

    }
    
    #game-container{
        padding-top : 65px !important;
    }
    
    #swipe-container{
         height: calc(100% - 75px) !important;
    }

    .playground .memory-card .front label{
        /* left : 8px !important;*/
        top : 0 !important;

    }

    .playground .memory-card .front {
        background-size: cover !important; 
    }

    .playground .memory-card .back   img{
        height : 100px !important;
    }

    .module-number{

        width: 36px !important;

    }

    .help-block.invisible{
        display : none;
    }

    .col-8{
        max-width : 100% !important;
        flex-basis : 100% !important; 
    }
    .checkmark{
        left : 10px !important;
    }
    .modul-type-name{
        /*  display : none !important;*/
        border-right : 3px solid white !important;
        font-size:12px !important;
        line-height: 40px;
        padding-top : unset !important;
        padding-bottom : unset !important;
    }

    .btn-backward, .btn-forward, .btn-module, .btn-home {
        width : 26px !important;
        height : 33px !important;
        background-size : 25px auto !important;
        margin-left : 5px !important;
    }
    .btn-backward {
        margin-left : unset !important;
    }

    #login_label{
        margin-top : unset !important;
    }


    .goto-modules{

        height: 33px !important;
        font-size: 8px !important;
        max-width: 110px;
        min-width: 120px !important;
        margin-top : unset !important;
    }

    .game-pictures img{
        width : 90px !important;
        height : 90px !important;
    }
    .label .label-number, .label-name{
        font-size : 8px !important;
      /*  width : 25px !important;
        font-size : 12px !important;

*/
       /* min-height: 30px !important;*/
    }
    
   
    #pairing_container {
    width: calc(90px * 4) !important;
    overflow : hidden;
    
    }
    
    .label{
        width:90px !important;
    }

    .label .label-name{
     /*   width : 65px !important;
        font-size : 15px !important;

        padding-top: 3px !important;
        padding-left: 7px !important;
        font-size : 9px !important;
        min-height: 30px !important;
*/
      min-height: 30px !important;
    }

    .game-pictures .label {
        /*max-width : 100%;*/
    }

    .pairing_text{
        width : 90px !important;
        height : 90px !important;
        font-size : 9px !important;
       /* padding-top : 30px !important;*/

    }

    #good_pairs{
        display : none !important;
    }
    .game-pictures{
          /*width:90px !important;
        height : 120px !important;*/

    }

    .label-name-changed {
        margin-left: 20% !important;
    }

    .endpair {
        widht : 95px !important;
    }

    .endpair .game-pictures {

      /*  height: 140px !important;*/
    }
    .quiz_answear{
        min-width : 100%;
    }

    .theme-button {
        max-width : 50px !important;
        min-width : 50px !important;
        font-size : 12px !important;
    }


    .quiz_buttons{


        height : 33px !important;

        max-width : 40px !important;
        font-size : 5px !important;
        min-width : 40px !important;
        margin-top : unset !important;

    }
    #end-modules-button, #end-modules-button2{
        font-size : 10px !important;
    }


    .congratulation-text{

        font-size : 43px !important;
    }

    .gameend-body{
        /*  height : 100% !important;*/
    }


    .endpair {
        width: 100px !important;

    }

    #quiz_container{
        /* margin-top : 40px;*/
    }

    #quiz_container .black_border {
        font-size:20px !important;
        min-height: 40px !important;
    }

    .module-number-online {

        padding : 5px !important;
        width : 35px !important;
    }

    #game-container #module-middle-footer{
        text-align: center !important;
    }

    #game-container .simple_quiz_comment p{
        padding : 20px !important;
    }
    .picturequiz-img{

        height : 180px !important;
        width : 100% !important;
        position : inherit !important;
        object-position: center !important;
        left : unset !important;
       
        margin-bottom : 20px;

    }

    .endpicturequiz .simple_quiz_comment{
        width : 100% !important;
        margin-top : -200px !important;

    }

    .endpicturequiz .simple_quiz_comment p{
        font-size : 12px !important;
        padding : 5px !important;



    }
    #end-module-container h1{
        font-size : 30px !important;
    }
    
    .picture-comment{
        font-size : 12px !important;
        width : 180px !important;
        max-height:90px !important;
    }
    
     #end-module-container p{
        max-width : 80% !important;
    }
    .endpicturequiz  .simple_comment_sign{
        margin-bottom : 20px !important;
        padding : 5px !important;

    }
    
    #quiz_container, .endquiz {
        margin-top : unset !important;
    }
    
  /*  .answer_c{
        top : 60px;
        z-index : 100;
    }
    .answer_b{
        top : -60px;
    }*/
    #quiz_container .quiz_question {
        margin-top : unset !important;
    }


    .picture_quiz_next_button {

        margin-left: calc( (50% + 25%) - 71px) !important;
        position : relative !important;
    }

    .module-number-online{
        max-height: 40px ;
        font-size : 10px !important;
    }

    .picturequiz{
        margin : 0 auto !important;
    }

    .swipe-images{

        width : 80% !important;
        min-width : 300px !important;
        margin-top : 30px !important;
    }

    .img-comp-container img{
        width :80% !important;
        min-width: 300px !important;
    }

    .swipe-container .btn{
        /* margin : unset !important
         margin-top : unset !important;*/
        position : absolute;
    }

    .swipe-container #forward_button{
        left : calc(50% + 35px);
        margin-top : 110% !important;
    }
    .swipe-container .btn-backward{
        left : calc(50% - 35px);
        margin-top : 110%;
    }

    .endpairs{
        text-align: center;
    }
    .endpairs .pair-label {
       /* margin-top: unset !important; */
        top: 0;
        text-align: left;
    }
    .endlabel {
        position : relative;
        top : 0;
    }

    .endlabel .label-name{
        width : 50px !important;
    }
    #loginButton{
        position : absolute !important;
        left : 50px;
        top : 50px;
    }
    #left-side p{
        position : absolute !important;
        top : 490px;
        font-size : 36px !important;
        margin-top : unset !important;
    }

    .footer{
        display : none;
    }

    #login #left-side {
        display : none !important;
    }

    .row-default {
        display : none !important;
    }

    .welcome-body  {
        background-image : url("../images/mobile_welcome.png") !important;
    }

    .welcome-body .muzped-black-link{
        display : none;
    }
    .row-mobile .open-eye, .closed-eye{
        vertical-align : middle;
        display : inline-block;
        width : 40px !important;
    }

    .row-mobile input.muzped-input {
        width : 100% !important;
    }

    .row-mobile button.muzped-input {
        width : 100% !important;
        font-weight: bold;
        text-transform: uppercase;
    }

    .muzped-input input{
        background : transparent;
        outline : none;
        border : none;
        padding-left : 10px;
        text-align: center;
        color : white;
    }

    .muzped-input input::placeholder{
        color : white;
    }

    .row-mobile #login_label {
        margin-bottom : 70px !important;
        margin-top : 20px !important;
    }

    .row-mobile .muzped-logo{
        width : 200px !important;
        background-size : contain;
        float : right ;

    }

    #quiz_container  .answer-sign{
        width : 40px !important;
        min-height : 40px !important;
        border : 3px solid white !important;
    }

    #quiz_container .quiz_question{
        font-size : 12px !important;

    }

    #circle{
        height : 40px !important;
        width : 40px !important;
        left : 80px !important;
    }

    #quiz_container .quiz_answear{
        font-size : 12px !important;
        padding-left : 50px !important;
    }
    .row-mobile .close{
        margin-top : unset !important;
    }

    .row-mobile .login_close{
        width : 40px !important;
        margin-left : unset !important;
    }

    .row-mobile{
        display : inline !important;
    }
    .login-body{
      /*  background-image : unset !important;*/
        background : #36BAA2;
    }
    
    .akademia-muzpedmuzped_back .login-body  #login_label{
        width : 320px !important;
        font-size : 30px;
    }

    .akademia-muzpedmuzped_back .login-body{
      /*  background-image : unset !important;*/
        background : #CA9E67;
    }

    .login-body .muzped-logo{
        background-image : url("../images/white_muzped_logo.png")
    }

    .carousel-element{
        /* display: none;*/
        /*  top : 200px;
          margin-left : 410px;
          position : absolute;*/
        max-width: 100vw !important;
        display : inline-block;

    }
    .active.carousel-element{

        /*  display : inline;*/
    }
    .left-dis  {
        animation: fadeOut 2s ease-out;

    }

    .left-come {
        animation: fadeIn 1s ease-out;

    }

    .carousel-list {
        max-height: 600px;
        overflow: hidden;
        width : 2000px;
        display : inline-block;
    }

    .carousel-bullets {
        position :fixed !important;
        bottom : 0;
        margin : 0 auto;
        text-align: center;
        font-size : 55px;

    }
    .carousel-bullet{
        display:inline-block;
        height: 20px;
        width: 20px;
        background-color: #555;
        margin : 10px;
    }

    .active.carousel-bullet{
        transform : rotate(45deg);
        background  :#36BAA2;
    }

    .header{
        /* position : fixed;*/
        width : 100vw;
    }

    .theme_select_description p {
        font-size : 14px !important;

    }


    .element-with-background-image{
        -webkit-mask-image: url("../images/tematika_mobile.png") !important;
        mask-image: url("../images/tematika_mobile.png") !important;
        height : 600px !important;
        mask-size : cover !important;
        -webkit-mask-size :cover !important;
        -webkit-mask-position : center;
        mask-position : center;
    }


    .theme-list-div{
        overflow-x: auto !important;
    }


    .element-with-background-image::before {
        height : 600px !important;
    }
    .theme-list-div .theme_title {

        padding-bottom: 40px !important;
        padding-left : 40px !important;
    }

    .element-with-background-image img {

        height: 600px !important;

    }

    @keyframes fadeIn {
        0% {right:-100px;}

        100% {right: 0 !important; }
    }
    /*This is a fade out example. */
    @keyframes fadeOut {
        0% {right:200px;}

        100% {right: -700px !important; display : none !important; }
    }

    @keyframes hide {
        0% {
            left: 0;
            transform: translateX(0);
        }
        100% {
            left: 100%;
            transform: translateX(-100%);
        }
    }

    @keyframes come {
        to {
            left: ;

        }


    }
    #module-next-question .theme_button{
        max-width: 80px;
        font-size: 10px;
        width: 80px;
        min-width : 80px;
        vertical-align: top;
    }
    #module-next-question {
        min-width : 180px;
    }
}

@media (max-width : 340px){
    .theme_select_description {
        max-height: 200px !important;
    }
}
@media (min-width : 850px) and (max-width : 1000px){
    .swipe-images{

        width : 80% !important;
        min-width : 500px !important;
    }

    .img-comp-container img{
        width :80% !important;
        min-width: 500px !important;
    }

    .swipe-container .btn{
        margin : unset !important;
        margin-top : unset !important;
    }

}
@media (min-width : 750px) and (max-width : 840px){
    .ages_div{
        padding-top : 20px !important;
    }

    .active_age{
        min-height: 200px !important;
    }


    .ages_div p{
        font-size : 15px !important;
        padding : 5px !important;
    }

    .active_age p{
        padding : 30px !important;
    }

    .ages_div span{
        font-size : 10px !important
    }


    .ages_div .age-border {

        max-width: calc(100% - 20px) !important;
    }
    .col-md-4 {
        max-width : 50% !important;
        min-width : 50%;
    }

}
@media screen and (min-width: 1200px)   and  (max-width: 1300px)  {
    .admin-body .container{
        min-width : 880px !important;
        width : 880px !important;
        margin-left : unset !important;
    }
    .container{
        min-width : 1000px
    }

    .form-input-title  {
        width : 110px !important;
    }

    #sidebar{
        padding : 25px !important;
        width:320px !important;
    }

    #sidebar-div{
        width : 320px !important;
    }

    .page-header h2{
        font-size:23px;
        display:inline !important;

    }

    #info-label-header{
        vertical-align: bottom !important;
    }

    .muzped-material-input{
        max-width : 100px;
    }

    .item-form-input{
        max-width: 620px;
        font-size: 17px;
    }

    #memory-item-container{
        max-width: 900px;
    }

    .image-gallery-content .image-gallery-slide .image-gallery-image {
        max-height: 401px !important;
    }

    #image-module-container{
        max-width : 500px;
    }



    #module-container {
        margin-top : 30px !important;
    }

    .ages_div{
        padding-top : 40px !important;
        min-height : 300px !important;
    }

    .ages_div p{
        padding : 15px !important;
        font-size : 30px;
    }


}
@media screen and (min-width : 840px) and (max-width : 1170px){
    .online-dashboard-text {
        position : relative !important;
        margin-top : 170px !important;
    }
}
@media screen and (max-height : 850px){
    #quiz_container{
    margin-top : unset !important;
    }
    
    #quiz_container .black_border {
    font-size: 18px !important;
      min-height: 50px !important;
    }
    
    .answer-sign {
    width: 50px !important;
    }
    
    #game-container .simple_quiz_comment p {
    font-size: 15px !important;
   
    padding: 20px !important;
    }
    .online-dashboard-text p{
        /*font-size : 15px;*/
    }
    
}

@media screen and (min-width: 1600px)     {
    .container-fluid{
        max-width: calc(100% - 100px) !important;
        margin : 0 auto !important;
         display:flex;
         align-content: center;
         position:relative;
         flex-direction: column;
         flex-basis: 1;
         
    }
}

@media screen and (min-width: 1300px)   and  (max-width: 1800px)  {
    .container{
        min-width : 1200px
    }


    #sidebar{
        padding : 25px !important;
        width:320px !important;
    }

    #sidebar-div{
        width : 320px !important;
    }

    .admin-body .container{
        min-width : 1000px;
        width : 1000px;
        margin-left : unset !important;
    }

    .page-header h2{
        font-size:23px;
        display:inline !important;

    }

    #info-label-header{
        vertical-align: bottom !important;
    }

    .muzped-material-input{
        max-width : 100px;
    }

    .item-form-input{
        max-width: 620px;
        font-size: 17px;
    }

    #memory-item-container{
        max-width: 900px;
    }

    .image-gallery-content .image-gallery-slide .image-gallery-image {
        max-height: 401px !important;
    }

   /* #image-module-container{
        max-width : 500px;
    }*/

    .playground .memory-card{
        margin-bottom : 20px !important;
    }

    .playground .memory-card .front label{
        top : 24px !important;
        left : 0 !important;
    }

    #module-container {
        margin-top : 30px !important;
    }

    .ages_div{
        padding-top : 40px !important;
        min-height : 300px !important;
    }

    .ages_div p{
        padding : 15px !important;
        font-size : 30px;
    }



    .form-input-title  {
        width : 110px important;
    }
}

@media screen  and  (max-width: 1800px) and (min-width: 1000px) {
    .theme-list-div .masked {
        max-height: 200px;
    }
    .theme-list-div .element-with-background-image{

        max-height:200px;


    }
    .theme-list-div .element-with-background-image img{

        max-height:200px;

    }


}




/*tata-test*/
.tata-muzpedmuzped_back .theme_green_button{
    background-color :#d1ad53;
}

.tata-muzpedmuzped_back .element-with-background-image:before{
    background-color :#d1ad53;
}

.tata-muzpedmuzped_back .gameend-body:before{
    background-color :#142f53;
}

.tata-muzpedmuzped_back  .ages_div{
    background : url("../images/tata_ages_div.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.tata-muzpedmuzped_back  .inactive_ages_div{
    background : url("../images/inactive_age_select.png");
    background-size : contain;
    background-repeat: no-repeat;
}

.tata-muzpedmuzped_back .muzped-text{
    background-image : url("../images/tata_muzped_text.png");
}
.tata-muzpedmuzped_back .muzped-icon{
    background-image : url("../images/tata_muzped_logo.png");
}

.tata-muzpedmuzped_back .btn-logout{
    background-image : url("../images/tata_logout.png");
}

.tata-muzpedmuzped_back .element-with-background-image .theme_title{
    color: #142f53;
}
.tata-muzpedmuzped_back .element-with-background-image .theme_description p{
    color: #142f53;
}
.tata-muzpedmuzped_back .black_border{
    border : 3px solid #d1ad53;
    color :#d1ad53;
}



.tata-muzpedmuzped_back .btn-backward{
    background-image : url("../images/tata_backward.png");

}

.tata-muzpedmuzped_back .btn-home{
    background-image : url("../images/tata_home.png");

}

.tata-muzpedmuzped_back .btn-forward{
    background-image : url("../images/tata_forward.png");

}

.tata-muzpedmuzped_back .description-head::before {
    background: #142f53;

}

.tata-muzpedmuzped_back  .description-text::before {
    background: #142f53;

}

.tata-muzpedmuzped_back .gameend-body .muzped-text{
    background-image : url("../images/tata_muzped_text.png");

}

.tata-muzpedmuzped_back .gameend-body .btn-forward{
    background-image : url("../images/tata_forward.png");
}

.tata-muzpedmuzped_back .gameend-body .btn-backward{
    background-image : url("../images/tata_backward.png");
}

.tata-muzpedmuzped_back .gameend-body .btn-home{
    background-image : url("../images/tata_home.png");

}


.tata-muzpedmuzped_back .gameend-body .black_border{
    border : 3px solid #d1ad53;
    color :#d1ad53;
}

.tata-muzpedmuzped_back #quiz_container .black_border{
    border : 3px solid white;
    color :white;
}

.tata-muzpedmuzped_back  .gameend-body .muzped-icon{
    background-image : url("../images/tata_muzped_logo.png");
}

.tata-muzpedmuzped_back .gameend-body .btn-logout{
    background-image : url("../images/tata_logout.png");
}

.tata-muzpedmuzped_back .gameend-body .btn-logout{
    background-image : url("../images/tata_logout.png");
}

.tata-muzpedmuzped_back .btn-module{
    background-image : url("../images/tata_modul.png");
}

.tata-muzpedmuzped_back .gameend-body .btn-module{
    background-image : url("../images/tata_modul.png");
}

.tata-muzpedmuzped_back .gameend-back{
    color : #d1ad53;
}

.muzped_back.tata-muzpedmuzped_back  {
    background-image : url("../images/tata_background.png");
    background-color: #142f53;
    color : white;
    background-position-y: 250px;
}

.theme_back.tata-muzpedmuzped_back  {
    background-image : url("../images/tata_background.png");
    background-color: #142f53;
    color : white;
    background-position-y: 250px;
}

.module_back.tata-muzpedmuzped_back  {
    background-image : url("../images/tata_background.png");
    background-color: #142f53;
    color : white;
    background-position-y: 250px;
}

.tata-muzpedmuzped_back .quiz_container .black_border{
    border : 3px solid white;
    color :white;
}

.login-body.tata-muzped{
    background-image :url("../images/tata_login.jpg") ;
}

.login-body.tata-muzped #left-side p{
    color : #d1ad53;
}

.welcome-body.tata-muzped{
    background-image :url("../images/tata_welcome.jpg") ;
}

.welcome-body.tata-muzped #left-side p{
    color : #d1ad53;
}


.tata-muzped .muzped-button{
    border: 3px solid #d1ad53;
    color : #d1ad53;
}

.tata-muzped .muzped-black-link{
    color : #d1ad53;
}

.tata-muzped .muzped-logo{

    background-image : url("../images/tata_logo.png");

}


.tata-muzped .gameend-body .final-quiz-button-open{
    background : #d1ad53;
    color : black;
    mix-blend-mode : lighten;

}

.tata-muzped .gameend-body .final-quiz-button{
    border: 3px solid #d1ad53;

}

.tata-muzpedmuzped_back .playground .memory-card .front {
    background-image : url("../images/tata_memory_card_back.jpg");

}

/*.tata-muzpedmuzped_back .endlabel .label-name {
  
    height: 80px;
}*/

.tata-muzpedmuzped_back  #pairing_container .label .label-name {

    display: none;

}

.tata-muzpedmuzped_back  #pairing_container .label .label-number {

    padding-top: 5px;
    padding-bottom: 5px;
    color: #142f53;
    background: #d1ad53;
}

.tata-muzpedmuzped_back  #pairing_container .label .label-name {

    padding-top: 5px;
    padding-bottom: 5px;
    color: #142f53;
    background: #d1ad53;
}

.tata-muzpedmuzped_back   .endlabel .label-number {

    padding-top: 5px;
    padding-bottom: 5px;
    color: #142f53;
    background: #d1ad53;
}

.tata-muzpedmuzped_back   .endlabel .label-name {

    padding-top: 5px;
    padding-bottom: 5px;
    color: #142f53;
    background: #d1ad53;
}


.tata-muzpedmuzped_back .pairing_text{
    color: #142f53;
    background: #d1ad53;
    border-radius: unset !important;
}


.tata-muzpedmuzped_back .description-text p::-webkit-scrollbar {
    width: 7px;               /* width of the entire scrollbar */
}
.tata-muzpedmuzped_back .description-text p::-webkit-scrollbar-track {
    background: #d1ad53;        /* color of the tracking area */
}
.tata-muzpedmuzped_back .description-text p::-webkit-scrollbar-thumb {
    background-color: #a2802a;    /* color of the scroll thumb */
    border-radius: none;       /* roundness of the scroll thumb */
    border: 2px solid #a2802a;  /* creates padding around scroll thumb */
}

.tata-muzpedmuzped_back .description-text p{
    scrollbar-width : thin;
    scrollbar-color: #a2802a #d1ad53;
}

.tata-muzpedmuzped_back .swipe-label{
    color : #d1ad53;
}

/*tata-test */

/*tata éles */
.tatamuzped_back .theme_green_button{
    background-color :#d1ad53;
}

.tatamuzped_back .element-with-background-image:before{
    background-color :#d1ad53;
}

.tatamuzped_back .gameend-body:before{
    background-color :#142f53;
}

.tatamuzped_back  .ages_div{
    background : url("../images/tata_ages_div.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.tatamuzped_back  .inactive_ages_div{
    background : url("../images/inactive_age_select.png");
    background-size : contain;
    background-repeat: no-repeat;
}

.tatamuzped_back .muzped-text{
    background-image : url("../images/tata_muzped_text.png");
}
.tatamuzped_back .muzped-icon{
    background-image : url("../images/tata_muzped_logo.png");
}

.tatamuzped_back .btn-logout{
    background-image : url("../images/tata_logout.png");
}

.tatamuzped_back .element-with-background-image p{
    color: #142f53;
}

.tatamuzped_back .black_border{
    border : 3px solid #d1ad53;
    color :#d1ad53;
}



.tatamuzped_back .btn-backward{
    background-image : url("../images/tata_backward.png");

}

.tatamuzped_back .btn-home{
    background-image : url("../images/tata_home.png");

}

.tatamuzped_back .btn-forward{
    background-image : url("../images/tata_forward.png");

}

.tatamuzped_back .description-head::before {
    background: #142f53;

}

.tatamuzped_back  .description-text::before {
    background: #142f53;

}

.tatamuzped_back .gameend-body .muzped-text{
    background-image : url("../images/tata_muzped_text.png");

}

.tatamuzped_back .gameend-body .btn-forward{
    background-image : url("../images/tata_forward.png");
}

.tatamuzped_back .gameend-body .btn-backward{
    background-image : url("../images/tata_backward.png");
}

.tatamuzped_back .gameend-body .btn-home{
    background-image : url("../images/tata_home.png");

}


.tatamuzped_back .gameend-body .black_border{
    border : 3px solid #d1ad53;
    color :#d1ad53;
}

.tatamuzped_back #quiz_container .black_border{
    border : 3px solid white;
    color :white;
}

.tatamuzped_back  .gameend-body .muzped-icon{
    background-image : url("../images/tata_muzped_logo.png");
}

.tatamuzped_back .gameend-body .btn-logout{
    background-image : url("../images/tata_logout.png");
}

.tatamuzped_back .gameend-body .btn-logout{
    background-image : url("../images/tata_logout.png");
}

.tatamuzped_back .btn-module{
    background-image : url("../images/tata_modul.png");
}

.tatamuzped_back .gameend-body .btn-module{
    background-image : url("../images/tata_modul.png");
}

.tatamuzped_back .gameend-back{
    color : #d1ad53;
}

.muzped_back.tatamuzped_back  {
    background-image : url("../images/tata_background.png");
    background-color: #142f53;
    color : white;
    background-position-y: 250px;
}

.theme_back.tatamuzped_back  {
    background-image : url("../images/tata_background.png");
    background-color: #142f53;
    color : white;
    background-position-y: 250px;
}

.module_back.tatamuzped_back  {
    background-image : url("../images/tata_background.png");
    background-color: #142f53;
    color : white;
    background-position-y: 250px;
}

.tatamuzped_back .quiz_container .black_border{
    border : 3px solid white;
    color :white;
}

.login-body.tata{
    background-image :url("../images/tata_login.jpg") ;
}

.login-body.tata #left-side p{
    color : #d1ad53;
}

.welcome-body.tata{
    background-image :url("../images/tata_welcome.jpg") ;
}

.welcome-body.tata #left-side p{
    color : #d1ad53;
}


.tata .muzped-button{
    border: 3px solid #d1ad53;
    color : #d1ad53;
}

.tata .muzped-black-link{
    color : #d1ad53;
}

.tata .muzped-logo{

    background-image : url("../images/tata_logo.png");

}


.tata .gameend-body .final-quiz-button-open{
    background : #d1ad53;
    color : black;
    mix-blend-mode : lighten;

}

.tata .gameend-body .final-quiz-button{
    border: 3px solid #d1ad53;

}

.tatamuzped_back .playground .memory-card .front {
    background-image : url("../images/tata_memory_card_back.jpg");

}

/*.tata-muzpedmuzped_back .endlabel .label-name {
  
    height: 80px;
}*/

.tatamuzped_back  #pairing_container .label .label-name {

    display: none;

}

.tatamuzped_back  #pairing_container .label .label-number {

    padding-top: 5px;
    padding-bottom: 5px;
    color: #142f53;
    background: #d1ad53;
}

.tatamuzped_back  #pairing_container .label .label-name {

    padding-top: 5px;
    padding-bottom: 5px;
    color: #142f53;
    background: #d1ad53;
}

.tatamuzped_back   .endlabel .label-number {

    padding-top: 5px;
    padding-bottom: 5px;
    color: #142f53;
    background: #d1ad53;
}

.tatamuzped_back   .endlabel .label-name {

    padding-top: 5px;
    padding-bottom: 5px;
    color: #142f53;
    background: #d1ad53;
}


.tatamuzped_back .pairing_text{
    color: #142f53;
    background: #d1ad53;
}


.tatamuzped_back .description-text p::-webkit-scrollbar {
    width: 7px;               /* width of the entire scrollbar */
}
.tatamuzped_back .description-text p::-webkit-scrollbar-track {
    background: #d1ad53;        /* color of the tracking area */
}
.tatamuzped_back .description-text p::-webkit-scrollbar-thumb {
    background-color: #a2802a;    /* color of the scroll thumb */
    border-radius: none;       /* roundness of the scroll thumb */
    border: 2px solid #a2802a;  /* creates padding around scroll thumb */
}

.tatamuzped_back .description-text p{
    scrollbar-width : thin;
    scrollbar-color: #a2802a #d1ad53;
}

.tatamuzped_back .swipe-label{
    color : #d1ad53;
}


/*tata éles */


/*akadémia teszt*/
.akademia-muzpedmuzped_back .theme_green_button{
    background-color :#CA9E67;
}

.akademia-muzpedmuzped_back .element-with-background-image:before{
    background-color :#CA9E67;
}

.akademia-muzpedmuzped_back .gameend-body:before{
    /* background-color :#0E4A7D;*/

    background-color : unset !important;
    background: linear-gradient(0deg, rgba(11, 41, 69, 0.9), rgba(11, 41, 69, 0.9)), url("../images/THA_hatter.jpg"), #0E4A7D !important;
}

.akademia-muzpedmuzped_back  .ages_div{
    background : url("../images/THA-Muzped-Eletkor-Hatter.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.akademia-muzpedmuzped_back  .inactive_ages_div{
    background : url("../images/inactive_age_select.png");
    background-size : contain;
    background-repeat: no-repeat;
}

.akademia-muzpedmuzped_back .muzped-text{
   /* background-image : url("../images/THA-Muzped-Felirat-Teljes.png");
    width : 370px !important;
    background-size: 100% 40px;*/
   background : unset;
   width : 410px;
   font-weight: bold;
   text-align: center;
   padding-left:10px;
   padding-top : 10px;
   line-height : 40px;
   padding-top : unset !important;
   
}
.akademia-muzpedmuzped_back .muzped-icon{
    background-image : url("../images/white_muzped_icon.png");
}

.akademia-muzpedmuzped_back .modul-type-name{
    padding : unset !important;
    padding-left : 20px !important;
    padding-right : 20px !important;
    line-height: 40px;
    
}

.akademia-muzpedmuzped_back .btn-logout{
    background-image : url("../images/logout_white.png");
}

.akademia-muzpedmuzped_back .element-with-background-image .theme_title{
    color: #0E4A7D;
}
.akademia-muzpedmuzped_back #quiz_container .black_border .answer-sign{
    background-color : white;
    color : #0E4A7D;
}

.akademia-muzpedmuzped_back .element-with-background-image .theme_description p{
    color: white;
}

.akademia-muzpedmuzped_back .black_border{
    border : 2px solid white;
    color :white;

}



.akademia-muzpedmuzped_back .btn-backward{
    background-image : url("../images/white_backward.png");

}

.akademia-muzpedmuzped_back .btn-home{
    background-image : url("../images/white_home.png");

}

.akademia-muzpedmuzped_back .btn-forward{
    background-image : url("../images/white_forward.png");

}

.akademia-muzpedmuzped_back .description-head::before {
    background: #0E4A7D;

}

.akademia-muzpedmuzped_back  .description-text::before {
    background: #0E4A7D;

}

.akademia-muzpedmuzped_back .gameend-body .muzped-text{
   /* background-image : url("../images/THA-Muzped-Felirat-Teljes.png");
    width : 370px !important;
    background-size: 100% 40px;
   
*/
background-image : unset !important;
}

.akademia-muzpedmuzped_back .gameend-body .btn-forward{
    background-image : url("../images/white_forward.png");
}

.akademia-muzpedmuzped_back .gameend-body .btn-backward{
    background-image : url("../images/white_backward.png");
}

.akademia-muzpedmuzped_back .gameend-body .btn-home{
    background-image : url("../images/white_home.png");

}


.akademia-muzpedmuzped_back .gameend-body .black_border{
    border : 2px solid white;
    color :white;
}

.akademia-muzpedmuzped_back #quiz_container .black_border{
    /* border : 3px solid #CA9E67 !important;
     color :#CA9E67 !important;*/
    border-color:white;
    color : white;
}
.akademia-muzpedmuzped_back #quiz_container .answer-sign{
    border-color : white !important;
}
.akademia-muzpedmuzped_back  .gameend-body .muzped-icon{
    background-image : url("../images/white_muzped_icon.png");
}

.akademia-muzpedmuzped_back .gameend-body .btn-logout{
    background-image : url("../images/logout_white.png");
}

.akademia-muzpedmuzped_back .gameend-body .btn-logout{
    background-image : url("../images/logout_white.png");
}

.akademia-muzpedmuzped_back .btn-module{
    background-image : url("../images/white_modul.png");
}

.akademia-muzpedmuzped_back .gameend-body .btn-module{
    background-image : url("../images/white_modul.png");
}

.akademia-muzpedmuzped_back .gameend-back{
    color : #CA9E67;
}

.muzped_back.akademia-muzpedmuzped_back  {
    /*  background-image : url("../images/THA-Muzped-Terulominta.png");*/

    /*background-image : url("../images/THA_hatter.jpg");
      background-color: #0E4A7D;*/
    background: linear-gradient(0deg, rgba(11, 41, 69, 0.8), rgba(11, 41, 69, 0.8)), url("../images/THA_hatter.jpg"), #0E4A7D;
    color : white;
    background-size : cover;
}

/*.muzped_back.akademia-muzpedmuzped_back::before  {
    content: "";
    filter : blur(8px);
    background-image : url("../images/THA_hatter.jpg");
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(2px);
    z-index: -1;
}*/

.theme_back.akademia-muzpedmuzped_back  {
    background-image : url("../images/THA-Muzped-Terulominta.png");
    background-color: #0E4A7D;
    color : white;
    background-position-y: 250px;
}

.module_back.akademia-muzpedmuzped_back  {
    background-image : url("../images/THA-Muzped-Terulominta.png");
    background-color: #0E4A7D;
    color : white;
    background-position-y: 250px;
}

.akademia-muzpedmuzped_back .quiz_container .black_border{
    border : 3px solid #CA9E67 !important;
    color :#CA9E67 !important;
}


.akademia-muzpedmuzped_back .login-body{
    background: linear-gradient(0deg, rgba(11, 41, 69, 0.8), rgba(11, 41, 69, 0.8)), url("../images/THA_hatter.jpg"), #0E4A7D;
}

.akademia-muzpedmuzped_back .login-body .muzped-logo{
    background-image :url("../images/white_muzped_logo.png") ;
    background-position: bottom left;
}

.akademia-muzpedmuzped_back .login-body #login_label{
    background-image : url("../images/THA-Nagy-Kviz--Folt-Reg.png");
    color : white !important;
    border : none;
    background-size: contain;
    height: 180px;
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
    width: 500px ;
    margin-bottom: unset !important;
    font-size : 35px;
    line-height: 160px;
    margin-top : unset !important;
    
}

.akademia-muzpedmuzped_back .registration-body .muzped-input{
    border-color : #CA9E67 !important;
    color : #CA9E67 !important;
    outline : none;

}

.akademia-muzpedmuzped_back .registration-body .muzped-input::placeholder{

    color : #CA9E67 !important;
}


#registration-btn{
    background : #CA9E67;
    color : white !important;
    font-weight: bold;
}

.akademia-muzpedmuzped_back .login-body .tha-logo{
    background-image :url("../images/Logo-THA-Muzped.png") ;
    height: 80px;
    width: 77px;
    display: inline-block;
    background-repeat: no-repeat;
    vertical-align: bottom;
    text-align: left;
    /*border-left : 4px solid white;*/
    background-size : contain;
    background-position: left;

}
.akademia-muzpedmuzped_back #module-container {
    /* margin-top : 50px !important;*/
}
.akademia-muzpedmuzped_back .login-body #left-side p{
    color : white;
}

.login-body.akademia-muzped{
    background-image :url("../images/THA-Muzped-Kezdo-01.jpg") ;
}

.login-body.akademia-muzped #left-side p{
    color : white;
}

.welcome-body.akademia-muzped{
    background-image :url("../images/THA-Muzped-Kezdo-01.jpg") ;
}

.welcome-body.akademia-muzped #left-side p{
    color : white;
}


.akademia-muzped .muzped-button{
    border: 3px solid #CA9E67;
    color : #CA9E67;
}

.akademia-muzped .muzped-black-link{
    color : #CA9E67;
}

.akademia-muzped .muzped-logo{

    background-image : url("../images/white_muzped_icon.png");

}

.akademia-muzped .gameend-body::before{
    background-color : unset !important;
    background: linear-gradient(0deg, rgba(11, 41, 69, 0.9), rgba(11, 41, 69, 0.9)), url("../images/THA_hatter.jpg"), #0E4A7D !important;

}
.akademia-muzped .gameend-body .final-quiz-button-open{
    background : #CA9E67;
    color : black;
    mix-blend-mode : lighten;

}

.akademia-muzped .gameend-body .final-quiz-button{
    border: 3px solid #CA9E67;

}

/*.akademia-muzpedmuzped_back .playground .memory-card .front {
    background-image : url("../images/tata_memory_card_back.jpg");

}
*/

.akademia-muzpedmuzped_back  #pairing_container .label .label-name {

    /* display: none;*/

}

.akademia-muzpedmuzped_back   .pair {
    border-radius : unset !important;
} 
.akademia-muzpedmuzped_back  #pairing_container .label .label-number {

    padding-top: 5px;
    padding-bottom: 5px;
    color: #CA9E67;
    background: white;
}

.akademia-muzpedmuzped_back .twisted-label .label-name{
    background : rgb(14, 74, 125) !important;
}

.akademia-muzpedmuzped_back .twisted-label .label-number{
    color : rgb(14, 74, 125) !important;
}

.akademia-muzpedmuzped_back  #pairing_container .label .label-name {

    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    background: #CA9E67;
}

.akademia-muzpedmuzped_back   .endlabel .label-number {

    padding-top: 5px;
    padding-bottom: 5px;
    color: #0E4A7D;
    background: #CA9E67;
}

.akademia-muzpedmuzped_back   .endlabel .label-name {

    padding-top: 5px;
    padding-bottom: 5px;
    color: #0E4A7D;
    background: #CA9E67;
}


.akademia-muzpedmuzped_back .pairing_text{
    color: #0E4A7D;
    background: #CA9E67;
    border-radius: unset !important;
}


.akademia-muzpedmuzped_back .description-text p::-webkit-scrollbar {
    width: 7px;               /* width of the entire scrollbar */
}
.akademia-muzpedmuzped_back .description-text p::-webkit-scrollbar-track {
    background: #CA9E67;        /* color of the tracking area */
}
.akademia-muzpedmuzped_back .description-text p::-webkit-scrollbar-thumb {
    background-color: #a2802a;    /* color of the scroll thumb */
    border-radius: none;       /* roundness of the scroll thumb */
    border: 2px solid #a2802a;  /* creates padding around scroll thumb */
}

.akademia-muzpedmuzped_back .description-text p{
    scrollbar-width : thin;
    scrollbar-color: #a2802a #CA9E67;
}

.akademia-muzpedmuzped_back .swipe-label{
    color : #CA9E67;
}

.akademia-muzped #loginButton {
    border: 3px solid white;
    color: white;
}

#welcome .muzped-black-link{
    color : white;
}

.akademia-muzpedmuzped_back #left-side p {
    font-size : 28px !important;
    line-height : 1;
    margin-top : 20% !important;
}
 .akademia-muzpedmuzped_back .muzped-footer{
    position : relative !important;
    margin-top : 10px;
}


.akademia-muzpedmuzped_back .tour_footer.muzped-footer{
    position : absolute !important;
  
}

/*akadémia teszt*/

