.livegame-container .muzped-input{
    border-color : black;
    margin:0 auto;
    color : black;   
}

.livegame-container .muzped-input::placeholder {
    
    color: black;
}


.livegame-container .submit {
    width: 311.24px;
    height: 88.32px;
    background: #26547E;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    color: #FFFFFF;
    text-transform:uppercase;
    margin : 0 auto;
    margin-top : 30px;
}