
.login-body {
    background-image :url("../images/background_login.png") ;
   
    background-size : cover;
}


#login #left-side{
  /*  width : 50%;
    display : inline-block;
    float : left;
    padding-left : 50px;*/

 
  
  
}

#login #right-side{
   /* width : 50%;
    display:inline-block;
    padding-left : 30px;
    margin : 0 auto;
    float : right;
    text-align :center;
    padding-left:130px;
    padding-right : 50px;*/
  margin-right : 200px;

}

#left-side p{
    font-size :52px;
    margin-top : 190px;
    line-height: 80px;


}

.footer{
    margin-top : 100px;
    text-align : left;
    padding-left : 40px;
}

#login_label{
    margin-top : 115px;
    margin-bottom : 170px;

}

#login .button-login{
    background-image :url("../images/login_button.png") ;
    background-repeat: no-repeat;
    background-size: 40px;
    background-position: center;
    width: 170px;
    height:70px;
}

.login-body .col-lg-4{
    padding :0px;
    margin :0;
}

#login .closed-eye{
    background-image :url("../images/closed_eye.png") ;
    background-repeat: no-repeat;
    background-size: 40px;
    background-position: center;
    width: 40px;
    height:70px;
    margin : 0 auto;
}

.open-eye{
    background-image :url("../images/open_eye.png") ;
    background-repeat: no-repeat;
    background-size: 40px;
    background-position: center;
    width: 40px;
    height:76px;
    margin : 0 auto;
}

.close{
    color : #fff;
    font-size : 55px;
    font-weight: bold;
    margin-top : 45px;
}

.login_close{
     background-image :url("../images/login_close.png") ;
     height : 70px;
     width : 90px;
     display:inline-block;
     position:absolute;
     margin-left : 70px;
     background-repeat: no-repeat;
     background-size : contain;
}