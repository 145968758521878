

.admin-login-body {
    background-image :url("../images/admin_login.jpg");
   
    background-size : cover;
    color : white;
}


#login #left-side{
    
    display : inline-block;
    float : left;
    

}

#login #right-side{
   
    display:inline-block;
  
    margin : 0 auto;
    float : right;
    text-align :center;
   


}

#left-side p{
    font-size :66px;
    margin-top : 40%;


}

.footer{
    margin-top : 200px;
    text-align : left;
    padding-left : 20px;
}

#login_label{
    margin-top : 115px;
    margin-bottom : 170px;

}

.button-login{
    background-image :url("../images/login_button.png") ;
    background-repeat: no-repeat;
    background-size: 40px;
    background-position: center;
    width: 170px;
    height:76px;
}

.login-body .col-lg-4{
    padding :0px;
    margin :0;
}

.closed-eye{
    background-image :url("../images/closed_eye.png") ;
    background-repeat: no-repeat;
    background-size: 40px;
    background-position: center;
    width: 40px;
    height:76px;
    margin : 0 auto;
}

.open-eye{
    background-image :url("../images/open_eye.png") ;
    background-repeat: no-repeat;
    background-size: 40px;
    background-position: center;
    width: 80px;
    height:76px;
    margin : 0 auto;
}

.close{
    color : #fff;
    font-size : 55px;
    font-weight: bold;
    margin-top : 45px;
}